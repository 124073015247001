import React from 'react';
import { connect } from 'react-redux';
import { range } from 'underscore';

import NoDataMessage from '^/match-centre/no-data-message';
import PlayerCell from '^/match-centre/players/player-cell';
import { Match, Player, Team } from '^/match-centre/scorecard/types';
import { StoreState } from '^/match-centre/store/types';

import { matchSelector, teamsSelector } from '^/match-centre/selectors';

export interface StateProps {
  match: Match | null;
  teams: ReadonlyArray<Team>;
}

export type Props = StateProps;

function getPlayerRowKey(player1: Player | null, player2: Player | null) {
  let key = '';
  key += player1 ? player1.player_id : '';
  key += player2 ? player2.player_id : '';
  return key;
}

const NoPlayerData = React.memo(() => (
  <div className="wi-match-tabs-content wi-match-players-tab">
    <NoDataMessage text="There is no player data available." />
  </div>
));

const Players = ({ match, teams }: Props) => {
  if (!match) {
    return <NoPlayerData />;
  }
  const team1 = teams.find(team => team.team_id === match.team1_id) as Team;
  const team2 = teams.find(team => team.team_id === match.team2_id) as Team;
  const maxPlayers = Math.max(team1.player.length, team2.player.length);

  if (!maxPlayers) {
    return <NoPlayerData />;
  }

  return (
    <div className="wi-match-tabs-content wi-match-players-tab">
      <div className="wi-player-tab-teams">
        <div className="wi-player-tab-team">
          <div className="wi-player-tab-team-image">
            {match.team1_logo_url && <img src={match.team1_logo_url} />}
          </div>
          <div className="wi-player-tab-team-name">{match.team1_name}</div>
        </div>
        <div className="wi-player-tab-team">
          <div className="wi-player-tab-team-image">
            {match.team2_logo_url && <img src={match.team2_logo_url} />}
          </div>
          <div className="wi-player-tab-team-name">{match.team2_name}</div>
        </div>
      </div>
      <div className="wi-flex-table">
        {range(maxPlayers).map(index => {
          const playerTeam1 = team1.player[index];
          const playerTeam2 = team2.player[index];
          const key = getPlayerRowKey(playerTeam1, playerTeam2);
          return (
            <div key={key} className="wi-flex-table-row">
              <div className="wi-flex-table-column">
                {playerTeam1 && <PlayerCell player={playerTeam1} />}
              </div>
              <div className="wi-flex-table-column">
                {playerTeam2 && <PlayerCell player={playerTeam2} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  match: matchSelector(state),
  teams: teamsSelector(state),
});

export { Players };

export default connect(mapStateToProps)(React.memo(Players));
