import { Button } from '@dabapps/roe';
import React, { ReactElement } from 'react';

import NextIcon from '^/match-centre/common/next-icon';

const OVER_WIDTH: number = 74;
const DURATION: number = 60;
const CONTAINER_ID: string = 'wi-overs-list';

const easeInOutQuad = (t: number, b: number, c: number, d: number): string => {
  t /= d / 2;
  if (t < 1) {
    return `${(c / 2) * t * t + b}`;
  }
  t--;
  return `${(-c / 2) * (t * (t - 2) - 1) + b}`;
};

const onButtonClick = () => {
  const element = document.getElementById(CONTAINER_ID);

  if (element) {
    const scrollToLeft = (to: number, duration: number): void => {
      const start = element.scrollLeft;
      const change = to - start;
      const startDate = +new Date();

      const animateScroll = (): void => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollLeft = parseInt(
          easeInOutQuad(currentTime, start, change, duration),
          0
        );
        if (currentTime < duration) {
          window.requestAnimationFrame(animateScroll);
        } else {
          element.scrollLeft = to;
        }
      };

      animateScroll();
    };

    return scrollToLeft(element.scrollLeft + OVER_WIDTH, DURATION);
  }
};

export interface Props {
  children: ReadonlyArray<ReactElement>;
}

export const ScrollableMobileContainer = ({ children }: Props) => (
  <div className="wi-overs-list-container">
    <div id={CONTAINER_ID} className="wi-overs-list">
      {children}
    </div>

    <Button className="wi-scroll-button" onClick={onButtonClick}>
      <NextIcon className="wi-scroll-svg" />
    </Button>
  </div>
);

export default React.memo(ScrollableMobileContainer);
