import { createSelector, Selector } from 'reselect';

import { StringPropertyNames } from '^/match-centre/common/types';
import { Innings, Match, Team } from '^/match-centre/scorecard/types';
import {
  inningsSelector,
  matchSelector,
  teamsSelector,
} from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import { Weather } from '^/match-centre/summary-panel/types';

const MAX_TEAM_NAME_LENGTH: number = 15;

const makeTeamNameSelector = (
  nameLocation: StringPropertyNames<Match>,
  abbreviationLocation: StringPropertyNames<Match>
) => {
  return createSelector(
    matchSelector,
    (match): string | null =>
      match &&
      (match[nameLocation] &&
      (match[nameLocation]! as string).length < MAX_TEAM_NAME_LENGTH
        ? match[nameLocation]
        : match[abbreviationLocation])
  );
};

export const getTeamNameOne = makeTeamNameSelector(
  'team1_name',
  'team1_abbreviation'
);
export const getTeamNameTwo = makeTeamNameSelector(
  'team2_name',
  'team2_abbreviation'
);

export const selectTeamId = (state: StoreState, teamId: string) => teamId;

export const getTeamFromId = createSelector(
  teamsSelector,
  selectTeamId,
  (teams, id): Team | undefined => {
    return teams && teams.find(team => team.team_id === id);
  }
);

export const getTeamNameFromTeamId = createSelector(
  getTeamFromId,
  (team: Team | undefined): string | undefined => {
    return team && team.name;
  }
);

export const makeTeamIDSelector = (
  teamIdLocation: StringPropertyNames<Match>
) => {
  return createSelector(
    matchSelector,
    (match: Match | null): string | null =>
      match && (match[teamIdLocation] || '')
  );
};

export const getTeamOneId = makeTeamIDSelector('team1_id');
export const getTeamTwoId = makeTeamIDSelector('team2_id');

export const makeInningsPerTeamSelector = (
  teamIdSelector: Selector<StoreState, string | null>
) => {
  return createSelector(
    [teamIdSelector, inningsSelector],
    (
      teamId: string | null,
      allInnings: ReadonlyArray<Innings>
    ): ReadonlyArray<Innings> | [] =>
      (allInnings.length &&
        allInnings.filter(innings => innings.batting_team_id === teamId)) ||
      []
  );
};

export const getTeamOneInningsSelector = makeInningsPerTeamSelector(
  getTeamOneId
);
export const getTeamTwoInningsSelector = makeInningsPerTeamSelector(
  getTeamTwoId
);

export const makeClosureNameForLatestTeamInningsSelector = (
  innings: Selector<StoreState, ReadonlyArray<Innings> | null>
) => {
  return createSelector(
    [innings],
    (teamInnings): string | null =>
      (teamInnings &&
        teamInnings.length &&
        teamInnings[teamInnings.length - 1].closure_name) ||
      null
  );
};

export const getCurrentTeamOneClosureSelector = makeClosureNameForLatestTeamInningsSelector(
  getTeamOneInningsSelector
);

export const getCurrentTeamTwoClosureSelector = makeClosureNameForLatestTeamInningsSelector(
  getTeamTwoInningsSelector
);

export const getWeather = (state: StoreState): Weather | null => state.weather;
