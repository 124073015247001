import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import Chart from '^/match-centre/common/chart';
import {
  CUT_OUT_PERSENTAGE,
  DEFAULT_BLUE,
  DEFAULT_PRIMARY,
  DEFAULT_YELLOW,
  hexOrDefault,
  PITCHVIZ_OVERALL_COLOUR_CODE,
  PITCHVIZ_TOTAL,
} from '^/match-centre/common/utils';
import { pitchvizOverallSelector } from '^/match-centre/graphs/selectors';
import InfoButton from '^/match-centre/info-button';
import { updateInfoTooltip } from '^/match-centre/info-button/actions';
import { winvizMetaSelector } from '^/match-centre/info-button/selectors';
import { restProductMeta } from '^/match-centre/info-button/utils';
import {
  getColoursForMatch,
  getTeamDrawPercent,
  getTeamOnePercent,
  getTeamTiePercent,
  getTeamTwoPercent,
  hasWinvizDataSelector,
} from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import {
  getTeamNameOne,
  getTeamNameTwo,
} from '^/match-centre/summary-panel/selectors';
import { ProductMeta } from '^/match-centre/summary-panel/types';

const BAR_THICKNESS: number = 16;

export interface StateProps {
  displayTeamNameOne: string | null;
  displayTeamNameTwo: string | null;
  matchColours: ReadonlyArray<string>;
  teamOnePercent: number;
  teamTwoPercent: number;
  pitchvizOverall: number;
  tiePercent: number;
  drawPercent: number;
  hasWinviz: boolean;
  meta: ReadonlyArray<ProductMeta>;
}

export interface DispatchProps {
  toggleTooltip: typeof updateInfoTooltip;
}

export type Props = StateProps & DispatchProps;

const TOOLTIP_ID: string = 'wi-stats-info';

export const StatsDisplay = ({
  displayTeamNameOne,
  displayTeamNameTwo,
  matchColours: [team1Colour, team2Colour],
  teamOnePercent,
  teamTwoPercent,
  pitchvizOverall,
  tiePercent,
  drawPercent,
  hasWinviz,
  toggleTooltip,
  meta,
}: Props) => (
  <div className="wi-stats-display">
    <InfoButton
      direction="left"
      tooltipClassName="wi-info-stats-tooltip"
      tooltipId={TOOLTIP_ID}
      onToggle={toggleTooltip}
      meta={meta}
    />

    {Boolean(pitchvizOverall) && (
      <div className="wi-chart-doughnut">
        <Chart
          id="wi-canvas-doughnut"
          type="doughnut"
          datasets={[
            {
              data: [pitchvizOverall, PITCHVIZ_TOTAL - pitchvizOverall],
              backgroundColor: [
                PITCHVIZ_OVERALL_COLOUR_CODE[pitchvizOverall],
                DEFAULT_PRIMARY,
              ],
            },
          ]}
          options={{
            responsive: true,
            cutoutPercentage: CUT_OUT_PERSENTAGE,
            maintainAspectRatio: false,
            rotation: Math.PI,
            elements: {
              arc: {
                borderWidth: 0,
              },
            },
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            animation: {
              duration: 0,
            },
          }}
        />

        <div className="wi-chart-doughnut-label">
          <div className="wi-chart-doughnut-title">PitchViz</div>
          <div className="wi-chart-doughnut-score">
            {pitchvizOverall}/{PITCHVIZ_TOTAL}
          </div>
        </div>
      </div>
    )}

    {hasWinviz && (
      <div
        className={classnames('wi-chart-stacked-container', {
          'full-width': !pitchvizOverall,
        })}
      >
        <div className="wi-chart-stacked-title">WinViz</div>

        <div className="wi-chart-stacked">
          <Chart
            id="wi-canvas-stacked"
            type="horizontalBar"
            datasets={[
              {
                data: [teamOnePercent],
                backgroundColor: [hexOrDefault(team1Colour, DEFAULT_YELLOW)],
              },
              {
                data: [tiePercent + drawPercent],
                backgroundColor: [DEFAULT_PRIMARY],
              },
              {
                data: [teamTwoPercent],
                backgroundColor: [hexOrDefault(team2Colour, DEFAULT_BLUE)],
              },
            ]}
            options={{
              responsive: true,
              scales: {
                xAxes: [
                  {
                    display: false,
                    stacked: true,
                    barThickness: BAR_THICKNESS,
                  },
                ],
                yAxes: [
                  {
                    display: false,
                    stacked: true,
                    barThickness: BAR_THICKNESS,
                  },
                ],
              },
              elements: {
                arc: {
                  borderWidth: 0,
                },
              },
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              animation: {
                duration: 0,
              },
            }}
          />
        </div>

        <div className="wi-chart-stacked-labels">
          <div className="wi-chart-stacked-label">
            {displayTeamNameOne} - {teamOnePercent}%
          </div>
          <div className="wi-chart-stacked-label">
            {teamTwoPercent}% - {displayTeamNameTwo}
          </div>
        </div>
      </div>
    )}
  </div>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  displayTeamNameOne: getTeamNameOne(state),
  displayTeamNameTwo: getTeamNameTwo(state),
  matchColours: getColoursForMatch(state),
  teamOnePercent: getTeamOnePercent(state),
  teamTwoPercent: getTeamTwoPercent(state),
  pitchvizOverall: pitchvizOverallSelector(state),
  tiePercent: getTeamTiePercent(state),
  drawPercent: getTeamDrawPercent(state),
  hasWinviz: hasWinvizDataSelector(state),
  meta: restProductMeta(winvizMetaSelector(state)),
});

const mapDispatchToProps = {
  toggleTooltip: updateInfoTooltip,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(StatsDisplay));
