import classnames from 'classnames';
import React from 'react';

import { FlexTableColumn } from '^/match-centre/scorecard/types';

export interface OwnProps<T> {
  columns: ReadonlyArray<FlexTableColumn<T>>;
  rows: ReadonlyArray<T>;
  className?: string | null;
  rowClassName?: (row: T) => string;
}

export type Props<T> = OwnProps<T>;

export function FlexTable<T>({
  columns,
  rows,
  className,
  rowClassName,
}: Props<T>) {
  const showTotalRow = columns.some(column => !!column.total);
  return (
    <div className={classnames('wi-flex-table', className)}>
      <div className={classnames('wi-flex-table-row', 'header')}>
        {columns &&
          columns.map((column, columnIndex: number) => (
            <div
              key={columnIndex}
              className={classnames('wi-flex-table-column')}
            >
              {column.header}
            </div>
          ))}
      </div>

      {rows.map((row, rowIdx: number) => (
        <div
          key={rowIdx}
          className={classnames(
            'wi-flex-table-row',
            rowClassName && rowClassName(row)
          )}
        >
          {columns.map((column, columnIndex: number) => (
            <div
              key={columnIndex}
              className={classnames('wi-flex-table-column')}
            >
              {column.value(row)}
            </div>
          ))}
        </div>
      ))}

      {showTotalRow && (
        <div className={classnames('wi-flex-table-row', 'total')}>
          {columns.map((column, columnIndex: number) => (
            <div
              key={columnIndex}
              className={classnames('wi-flex-table-column')}
            >
              {column.total}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default React.memo(FlexTable);
