import ColorDiff from 'color-diff';
import { createSelector } from 'reselect';

import { ArenaId } from '^/match-centre/arena-blog/types';
import { StringPropertyNames } from '^/match-centre/common/types';
import { hexToLabColor } from '^/match-centre/common/utils';
import { PlayerPages } from '^/match-centre/players/types';
import {
  Batting,
  Bowling,
  Innings,
  InningsTeamInfo,
  Match,
  Pitchviz,
  Player,
  Scorecard,
  Team,
  Winviz,
} from '^/match-centre/scorecard/types';
import {
  BattedStatus,
  BattingHand,
  BattingHandAcronym,
} from '^/match-centre/scorecard/types';
import { StoreState } from '^/match-centre/store/types';
import { MatchStatus, Tooltips } from '^/match-centre/summary-panel/types';

export const scorecardSelector = (state: StoreState): Scorecard | null =>
  state.scorecard;

export const infoTooltipsSelector = (state: StoreState): Set<string> =>
  state.infoTooltips;

export const tooltipsSelector = (state: StoreState): Tooltips | null =>
  state.tooltips;

export const arenaIdSelector = (state: StoreState): ArenaId => state.arenaId;

export const hasArenaId = createSelector(
  arenaIdSelector,
  (arenaId): boolean => Boolean(arenaId)
);

export const inningsSelector = createSelector(
  scorecardSelector,
  (scorecard): ReadonlyArray<Innings> => (scorecard && scorecard.innings) || []
);

export const teamsSelector = createSelector(
  scorecardSelector,
  (scorecard): ReadonlyArray<Team> => (scorecard && scorecard.team) || []
);

export const matchSelector = createSelector(
  scorecardSelector,
  (scorecard): Match | null => scorecard && scorecard.match
);

export const winvizSelector = createSelector(
  scorecardSelector,
  (scorecard): Winviz | null => scorecard && scorecard.winviz
);

export const hasWinvizDataSelector = createSelector(
  winvizSelector,
  (winviz: Winviz | null): boolean =>
    Boolean(
      winviz &&
        winviz.hasOwnProperty('team1_percent') &&
        winviz.hasOwnProperty('team2_percent') &&
        winviz.hasOwnProperty('tie_percent') &&
        winviz.hasOwnProperty('draw_percent')
    )
);

export const pitchvizSelector = createSelector(
  scorecardSelector,
  (scorecard): ReadonlyArray<Pitchviz> | null => scorecard && scorecard.pitchviz
);

export const matchIdSelector = createSelector(
  matchSelector,
  (match): string | null => match && match.match_id
);

export const matchStatusSelector = createSelector(
  matchSelector,
  match => match && match.match_status
);

export const isMatchComplete = createSelector(
  matchStatusSelector,
  (matchStatus): boolean => matchStatus === MatchStatus.COMPLETE
);

export const isMatchLive = createSelector(
  matchStatusSelector,
  (matchStatus): boolean => matchStatus === MatchStatus.LIVE
);

export const hasMatchStarted = createSelector(
  inningsSelector,
  (innings): boolean =>
    Boolean(
      innings && innings.length && innings[0].batted !== BattedStatus.NOT_BATTED
    )
);

export const isMatchForthcoming = createSelector(
  matchStatusSelector,
  (matchStatus): boolean => matchStatus === MatchStatus.FORTHCOMING
);

export const inPlayInningsSelector = createSelector(
  inningsSelector,
  (innings): ReadonlyArray<Innings> =>
    innings.filter(item => item.batted !== BattedStatus.NOT_BATTED) || []
);

export const reverseSortedInPlayInningsSelector = createSelector(
  inPlayInningsSelector,
  (innings): ReadonlyArray<Innings> =>
    [...innings].sort(
      (a, b) => Number(b.innings_number) - Number(a.innings_number)
    )
);

export const addActualInningsSelector = createSelector(
  inPlayInningsSelector,
  (innings): ReadonlyArray<Innings> => {
    const newInnings = [...innings];
    newInnings.forEach((i, index: number) => {
      if (index <= 1) {
        i.actual_innings_number = '1';
      } else if (index >= 2 && index <= 3) {
        i.actual_innings_number = '2';
      }
    });
    return newInnings;
  }
);

export const hasInnings = createSelector(
  inningsSelector,
  (innings): boolean | null => innings && innings.length > 0
);

export const selectedInningsTabSelector = (state: StoreState) =>
  state.activeInningsTab;

export const currentInningsSelector = createSelector(
  inningsSelector,
  (inningsCollection): Innings | null =>
    inningsCollection.find(innings => innings.live_status === 'current') || null
);

export const latestInningsSelector = createSelector(
  inningsSelector,
  (innings): string | null => {
    return innings.length ? innings[innings.length - 1].innings_number : null;
  }
);

export const initialActiveInningsSelector = createSelector(
  currentInningsSelector,
  hasMatchStarted,
  isMatchComplete,
  latestInningsSelector,
  (
    currentInnings,
    hasStarted,
    isComplete,
    latestInningsNumber
  ): string | null => {
    if (hasStarted && currentInnings) {
      return currentInnings.innings_number;
    } else if (isComplete) {
      return latestInningsNumber;
    }
    return null;
  }
);

export const currentInningsTabSelector = createSelector(
  selectedInningsTabSelector,
  initialActiveInningsSelector,
  (activeTab, defaultTab): string | null => activeTab || defaultTab
);

export const selectedInningsSelector = createSelector(
  inningsSelector,
  currentInningsTabSelector,
  (inningsCollection, tab): Innings | null =>
    inningsCollection.find(innings => innings.innings_number === tab) || null
);

const makeInningsTeamInfoSelector = (
  teamType: StringPropertyNames<Innings>
) => {
  return createSelector(
    matchSelector,
    selectedInningsSelector,
    (match, activeInnings): InningsTeamInfo | undefined => {
      const battingTeamId = activeInnings && activeInnings[teamType];
      if (match) {
        const isTeamOne = match.team1_id === battingTeamId;
        return {
          logo_url: isTeamOne ? match.team1_logo_url : match.team2_logo_url,
          name: isTeamOne ? match.team1_name : match.team2_name,
        };
      }
    }
  );
};

export const inningsBattingTeamSelector = makeInningsTeamInfoSelector(
  'batting_team_id'
);
export const inningsBowlingTeamSelector = makeInningsTeamInfoSelector(
  'bowling_team_id'
);

export const currentBattingSelector = createSelector(
  currentInningsSelector,
  (currentInnings): ReadonlyArray<Batting> => {
    return (currentInnings && currentInnings.batting) || [];
  }
);

export const selectedBattingSelector = createSelector(
  selectedInningsSelector,
  (selectedInnings): ReadonlyArray<Batting> => {
    return (selectedInnings && selectedInnings.batting) || [];
  }
);

export const extraRunsSelector = createSelector(
  selectedInningsSelector,
  (selectedInnings): string | null => {
    return selectedInnings && selectedInnings.extras;
  }
);

export const currentBowlingSelector = createSelector(
  currentInningsSelector,
  (currentInnings): ReadonlyArray<Bowling> => {
    return (currentInnings && currentInnings.bowling) || [];
  }
);

export const selectedBowlingSelector = createSelector(
  selectedInningsSelector,
  (selectedInnings): ReadonlyArray<Bowling> => {
    return (selectedInnings && selectedInnings.bowling) || [];
  }
);

export const playerIdSelector = (state: StoreState, playerId: string) =>
  playerId;

export const allPlayersSelector = createSelector(
  teamsSelector,
  (teams): ReadonlyArray<Player> => {
    return [...teams].reduce(
      (allPlayers, team) => {
        return [...allPlayers, ...team.player];
      },
      [] as ReadonlyArray<Player>
    );
  }
);

export const playerSelector = createSelector(
  allPlayersSelector,
  playerIdSelector,
  (allPlayers, playerId): Player | undefined =>
    allPlayers.find(player => player.player_id === playerId)
);

export const playerNameAndBattingHand = createSelector(
  playerSelector,
  (player): string => {
    if (player) {
      const battingHand =
        player.batting_hand === BattingHand.RIGHT
          ? BattingHandAcronym.RIGHT
          : BattingHandAcronym.LEFT;
      const displayPlayerName = player.known_as ? player.known_as : player.name;
      return `${displayPlayerName} (${battingHand})`;
    } else {
      return 'N/A';
    }
  }
);

export const playerPageSelector = createSelector(
  (state: StoreState) => state.playerPages,
  (state: StoreState, playerId: string) => playerId,
  (playerPages: PlayerPages, playerId: string): string | undefined =>
    playerPages[playerId]
);

export const getColoursForMatch = createSelector(
  matchSelector,
  (match: Match | null): ReadonlyArray<string> => {
    if (match) {
      const team1Primary = hexToLabColor(match.team1_colour_primary);
      const team2Primary = hexToLabColor(match.team2_colour_primary);
      if (
        team1Primary &&
        team2Primary &&
        ColorDiff.diff(team1Primary, team2Primary) < 10
      ) {
        return [match.team1_colour_secondary, match.team2_colour_primary];
      }
      return [match.team1_colour_primary, match.team2_colour_primary];
    } else {
      return [];
    }
  }
);

const getWinvizTeamPercentSelector = (teamType: StringPropertyNames<Winviz>) =>
  createSelector(
    winvizSelector,
    (winviz: Winviz | null): number => (winviz ? Number(winviz[teamType]) : 0)
  );

export const getTeamOnePercent = getWinvizTeamPercentSelector('team1_percent');

export const getTeamTwoPercent = getWinvizTeamPercentSelector('team2_percent');

export const getTeamDrawPercent = getWinvizTeamPercentSelector('draw_percent');

export const getTeamTiePercent = getWinvizTeamPercentSelector('tie_percent');

export const totalRunsSelector = createSelector(
  selectedBattingSelector,
  (batters: ReadonlyArray<Batting>): string => {
    const totalRuns = batters.reduce((total: number, row: Batting) => {
      return total + (parseInt(row.runs, 10) || 0);
    }, 0);
    return totalRuns.toString();
  }
);

export const totalXrDiffSelector = createSelector(
  selectedBattingSelector,
  (batters: ReadonlyArray<Batting>): string => {
    const totalXrDiff = batters.reduce((total: number, batter: Batting) => {
      const xrDiff = batter.hasOwnProperty('xR_diff')
        ? batter.xR_diff || '0'
        : '0';
      return total + parseInt(xrDiff, 10);
    }, 0);
    return totalXrDiff.toString();
  }
);

export const totalWicketsAndOversSelector = createSelector(
  selectedInningsSelector,
  (innings: Innings | null): string => {
    return innings ? `(${innings.wickets} wickets, ${innings.overs})` : 'N/A';
  }
);
