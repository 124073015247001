import React from 'react';
import ReactDOM from 'react-dom';
import { CookieBanner, Container, Row, Column, Button } from '@dabapps/roe';

class CookieBannerWidget extends React.Component {
  render() {
    return (
      <CookieBanner
        position="bottom"
        className="padding-top-large padding-bottom-large"
        render={({ dismiss }) => (
          <Container>
            <Row className="flex-grid">
              <Column md={15} lg={16} className="display-flex">
                <div>
                  <h4>Cookies on windiescricket.com</h4>
                  <p>
                    Windiescricket.com uses cookies to improve the user
                    experience and provide appropriate information which we may
                    in the future personalise for you. If you continue on this
                    website you will be providing your consent to our use of
                    these cookies.
                  </p>
                </div>
              </Column>

              <Column md={9} lg={8} className="display-flex">
                <div className="display-flex">
                  <Button
                    component="a"
                    href="/cookie-policy/"
                    className="button pill info margin-right-base"
                  >
                    More information
                  </Button>

                  <Button onClick={dismiss} className="pill success">
                    Accept
                  </Button>
                </div>
              </Column>
            </Row>
          </Container>
        )}
      />
    );
  }
}

window.renderCookieBanner = element => {
  ReactDOM.render(<CookieBannerWidget />, element);
};
