import { LiveMatch, MatchStatus } from '^/match-centre-dashboard/store/types';
import React from 'react';

export interface StateProps {
  match: LiveMatch | null;
}
export type Props = StateProps;

export const StatusHeader = ({ match }: Props) =>
  match && (
    <div className="wi-status-header">
      {match.match_status && match.match_status === MatchStatus.LIVE && (
        <div className="wi-status-header-icon">
          <svg className="live-match-dot" viewBox="0 0 20 20">
            <circle
              className="live-match-dot-outer"
              stroke="#FBED00"
              stroke-width="2"
              cx="10"
              cy="10"
              r="8"
            />
            <circle
              className="live-match-dot-inner"
              fill="#FBED00"
              cx="10"
              cy="10"
              r="4"
            />
          </svg>
        </div>
      )}

      <div className="wi-status-header-content">
        <div className="wi-status-header-title">
          {match.match_status && match.match_status === MatchStatus.LIVE
            ? 'Live right now'
            : ''}
        </div>

        <div className="wi-status-header-text">{match.name}</div>
      </div>
    </div>
  );
