import { responsesReducer } from '@dabapps/redux-requests';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { allMatches } from '^/match-centre-dashboard/reducers';
import { StoreState } from '^/match-centre-dashboard/store/types';

const rootReducer = combineReducers<StoreState, AnyAction>({
  allMatches,
  responses: responsesReducer,
});

export default function prepopulateStore(initialState: Partial<StoreState>) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
}
