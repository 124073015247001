import React from 'react';

export interface Props {
  className?: string;
}

export const CloseIcon = (props: Props) => (
  <svg
    className={props.className}
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <g>
      <rect fill="none" height="34" width="34" y="-1" x="-1" />
    </g>
    <g>
      <path
        stroke="null"
        d="m17.595768,15.999999l13.306881,-13.306881c0.440771,-0.440771 0.440771,-1.154997 0,-1.595768s-1.154997,-0.440771 -1.595768,0l-13.306881,13.306881l-13.306881,-13.306705c-0.440771,-0.440771 -1.154997,-0.440771 -1.595768,0s-0.440771,1.154997 0,1.595768l13.306881,13.306705l-13.306881,13.306881c-0.440771,0.440771 -0.440771,1.154997 0,1.595768c0.220386,0.220386 0.509179,0.330578 0.797796,0.330578s0.57741,-0.110193 0.797796,-0.330578l13.307057,-13.306881l13.306881,13.306881c0.220386,0.220386 0.509179,0.330578 0.797796,0.330578s0.57741,-0.110193 0.797796,-0.330578c0.440771,-0.440771 0.440771,-1.154997 0,-1.595768l-13.306705,-13.306881z"
      />
    </g>
  </svg>
);

export default React.memo(CloseIcon);
