import moment from 'moment';
import { createSelector } from 'reselect';

import { DATE_FORMAT_ISO } from './constants';

import {
  GroupedRecentData,
  GroupedUpcomingData,
  Innings,
  LiveMatch,
  StoreState,
  UpcomingMatch,
} from './store/types';

const selectUpstreamData = createSelector(
  (state: Pick<StoreState, 'allMatches'>) => state.allMatches,
  allMatches => allMatches && allMatches.upstream_data
);

const selectUpstreamDataMatch = createSelector(
  selectUpstreamData,
  upstreamData => upstreamData && upstreamData.match
);

const selectUpstreamDataLive = createSelector(
  selectUpstreamDataMatch,
  upstreamDataMatch => upstreamDataMatch && upstreamDataMatch.live
);

const selectUpstreamDataUpcoming = createSelector(
  selectUpstreamDataMatch,
  upstreamDataMatch => upstreamDataMatch && upstreamDataMatch.forthcoming
);

const selectUpstreamDataCurrent = createSelector(
  selectUpstreamDataMatch,
  upstreamDataMatch => upstreamDataMatch && upstreamDataMatch.complete
);

export const hasLiveUpstreamMatchData = createSelector(
  selectUpstreamDataLive,
  live => (live ? live : [])
);
export const liveMatchesExist = createSelector(
  selectUpstreamDataLive,
  live => (live && live.length > 0 ? true : false)
);

export const upcomingMatchesExist = createSelector(
  selectUpstreamDataUpcoming,
  upcoming => (upcoming && upcoming.length > 0 ? true : false)
);

export const recentMatchesExist = createSelector(
  selectUpstreamDataCurrent,
  recent => (recent && recent.length > 0 ? true : false)
);

export const noMatchesExist = createSelector(
  liveMatchesExist,
  upcomingMatchesExist,
  recentMatchesExist,
  (live, upcoming, recent) => (!recent && !upcoming && !live ? true : false)
);

export const hasCurrentUpstreamMatchData = createSelector(
  selectUpstreamDataCurrent,
  current => (current ? current : [])
);

export const getColumnNumber = (numberOfLiveMatches: number): number => {
  switch (numberOfLiveMatches) {
    case 1:
      return 24;
    case 2:
      return 12;
    default:
      return 8;
  }
};

export const getTeamInnings = (
  innings: ReadonlyArray<Innings>,
  teamId: string
): ReadonlyArray<Innings> =>
  innings && innings.filter(inningsArray => inningsArray.team_id === teamId);

export const getClosureName = (
  innings: ReadonlyArray<Innings>
): string | number =>
  innings && innings.length && innings[innings.length - 1].closure_name;

export const getFormattedMatchesDate = (
  date: string,
  format: string = DATE_FORMAT_ISO
): string => {
  return moment(date).format(format);
};

export const getUniqueDates = (
  upcomingMatches: ReadonlyArray<UpcomingMatch> | null
): string[] => {
  if (upcomingMatches) {
    return upcomingMatches.reduce<string[]>((memo, match) => {
      const date = getFormattedMatchesDate(match.start_date_ast);

      return !memo.includes(date) ? memo.concat(date) : memo;
    }, []);
  }

  return [];
};

export const getUpcomingMatchesByDate = (
  upcomingMatches: ReadonlyArray<UpcomingMatch> | null,
  date: string
): UpcomingMatch[] => {
  if (upcomingMatches) {
    return upcomingMatches.filter(
      matches => getFormattedMatchesDate(matches.start_date_ast) === date
    );
  }

  return [];
};

export const getGroupedMatchesList = (
  upcomingMatches: ReadonlyArray<UpcomingMatch> | null
): GroupedUpcomingData[] => {
  if (upcomingMatches) {
    return getUniqueDates(upcomingMatches)
      .sort()
      .map<GroupedUpcomingData>(date => ({
        match_date: date,
        matches: getUpcomingMatchesByDate(upcomingMatches, date),
      }));
  }

  return [];
};

export const selectGroupedMatches = createSelector(
  selectUpstreamDataUpcoming,
  upcoming => getGroupedMatchesList(upcoming)
);

export const getUniqueRecentDates = (
  recentMatches: ReadonlyArray<LiveMatch> | null
): string[] => {
  if (recentMatches) {
    return recentMatches.reduce<string[]>((memo, match) => {
      const date = getFormattedMatchesDate(match.start_date_ast);

      return !memo.includes(date) ? memo.concat(date) : memo;
    }, []);
  }

  return [];
};

export const getRecentMatchesByDate = (
  recentMatches: ReadonlyArray<LiveMatch> | null,
  date: string
): LiveMatch[] => {
  if (recentMatches) {
    return recentMatches.filter(
      matches => getFormattedMatchesDate(matches.start_date_ast) === date
    );
  }

  return [];
};

export const getGroupedRecentMatchesList = (
  recentMatches: ReadonlyArray<LiveMatch> | null
): GroupedRecentData[] => {
  if (recentMatches) {
    return getUniqueRecentDates(recentMatches)
      .sort()
      .reverse()
      .map<GroupedRecentData>(date => ({
        match_date: date,
        matches: getRecentMatchesByDate(recentMatches, date),
      }));
  }

  return [];
};

export const selectGroupedRecentMatches = createSelector(
  selectUpstreamDataCurrent,
  recent => getGroupedRecentMatchesList(recent)
);
