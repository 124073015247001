import Pusher from 'pusher-js';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import { updateBbb } from '^/match-centre/bbb/actions';
import { Ball } from '^/match-centre/bbb/types';
import { updateScorecard } from '^/match-centre/scorecard/actions';
import {
  SUBSCRIBE_PUSHER_CHANNELS,
  UNSUBSCRIBE_PUSHER_CHANNELS,
} from '^/match-centre/scorecard/actions';
import { Scorecard } from '^/match-centre/scorecard/types';
import { matchIdSelector } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';

const PUSHER_APP_CLUSTER: string = 'eu';
const PUSHER_EVENT: string = 'update';

const constructScorecardChannelId = (id: string | null) =>
  `site-v2-match-${id}-scorecard`;

const constructBbbChannelId = (id: string | null) => `site-v2-match-${id}-bbb`;

const accessPusherSocket = (key: string) =>
  new Pusher(key, {
    cluster: PUSHER_APP_CLUSTER,
  });

export default (store: MiddlewareAPI<Dispatch<AnyAction>, StoreState>) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  switch (action.type) {
    case SUBSCRIBE_PUSHER_CHANNELS: {
      const socket = accessPusherSocket(action.payload);

      const scorecardChannel = socket.subscribe(
        constructScorecardChannelId(matchIdSelector(store.getState()))
      );

      const bbbChannel = socket.subscribe(
        constructBbbChannelId(matchIdSelector(store.getState()))
      );

      scorecardChannel.bind(PUSHER_EVENT, (data: Partial<Scorecard>) => {
        next(updateScorecard(data));
      });

      bbbChannel.bind(PUSHER_EVENT, (data: Ball) => {
        next(updateBbb(data));
      });

      break;
    }

    case UNSUBSCRIBE_PUSHER_CHANNELS: {
      const socket = accessPusherSocket(action.payload);

      socket.unsubscribe(
        constructScorecardChannelId(matchIdSelector(store.getState()))
      );

      socket.unsubscribe(
        constructBbbChannelId(matchIdSelector(store.getState()))
      );

      break;
    }

    default:
      break;
  }

  return next(action);
};
