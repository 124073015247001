import { Column, Row } from '@dabapps/roe';
import React, { PureComponent } from 'react';
import { LogoBadge } from '../common/logo-badge';
import { MapIcon } from '../common/map-icon';
import { Score } from '../score';
import { getClosureName, getColumnNumber, getTeamInnings } from '../selectors';
import { StatusHeader } from '../status-header';
import { LiveMatch } from '../store/types';
export interface ScoreStateProps {
  match: LiveMatch;
  numberOfMatches: number;
}
export class LiveMatches extends PureComponent<ScoreStateProps> {
  public render() {
    const {
      team1_id,
      team2_id,
      team1_logo_url,
      team1_name,
      team2_logo_url,
      team2_name,
      innings,
      gid,
      weather,
      result_string,
    } = this.props.match;
    const numberOfMatches = this.props.numberOfMatches;
    const numOfColumns = getColumnNumber(numberOfMatches);
    return (
      <>
        <Column className="column" lg={numOfColumns}>
          <div className="wi-fixture live">
            <StatusHeader match={this.props.match} />
            <div className="flex-grid">
              <div className="container-results">
                <div className="wi-fixture-team-outer">
                                              
                  <div className="wi-fixture-team-live-badge">
                    {team1_logo_url && (
                      <img
                        className="wi-fixture-team-live-badge-img"
                        src={team1_logo_url}
                        alt="Team badge"
                      />
                    )}
                    {!team1_logo_url && (
                      <LogoBadge className="wi-fixture-team-live-badge-img" />
                    )}
                  </div>
                                
                  <div className="wi-summary-panel-team-name">{team1_name}</div>
                     
                  <div className="wi-summary-panel-team-result">
                    <p>
                                        
                      <div className="wi-score-result-inner">
                                          
                        <Score innings={getTeamInnings(innings, team1_id)} />
                            
                      </div>
                    </p>
                    <small>
                      {getClosureName(getTeamInnings(innings, team1_id))}
                    </small>
                       
                  </div>
                </div>
                <div className="wi-fixture-team-outer">
                                                           
                  <div className="wi-fixture-team-live-badge">
                    {team2_logo_url && (
                      <img
                        className="wi-fixture-team-live-badge-img"
                        src={team2_logo_url}
                        alt="Team badge"
                      />
                    )}
                    {!team2_logo_url && (
                      <LogoBadge className="wi-fixture-team-live-badge-img" />
                    )}
                  </div>
                                
                  <div className="wi-summary-panel-team-name">{team2_name}</div>
                                
                  <div className="wi-summary-panel-team-result">
                      
                    <p>
                                        
                      <div className="wi-score-result-inner">
                                      
                        <Score innings={getTeamInnings(innings, team2_id)} />
                            
                      </div>
                    </p>
                    <small>
                      {getClosureName(getTeamInnings(innings, team2_id))}
                    </small>
                              
                  </div>
                    
                </div>
                      
                <div className="wi-live-weather-outer">
                  {weather && (
                    <div className="match-results-weather-outer">
                      <Row className="match-results-weather">
                        <Column
                          xs={12}
                          lg={14}
                          xl={15}
                          className="padding-left-none"
                        >
                          <a href={weather.link} target="_blank">
                            <div className="weather-link-logo">
                              <img
                                src="/static/images/accuweather_logo_white.png"
                                alt="Accuweather logo"
                              />
                            </div>

                            <div className="weather-link-location">
                              <MapIcon className="float-left map-marker-icon" />
                              <div className="float-left weather-link-title">
                                {weather.city}
                              </div>
                            </div>
                          </a>
                        </Column>

                        <Column xs={6} className="weather-temperature">
                          {weather.temperature}
                        </Column>

                        <Column
                          xs={6}
                          lg={4}
                          xl={3}
                          className="weather-icon padding-right-none"
                        >
                          <img
                            className="float-right"
                            src={weather.icon_white}
                            alt="Weather icon"
                          />
                        </Column>
                      </Row>
                    </div>
                  )}
                </div>
                {result_string && (
                  <div className="wi-match-results">
                                {result_string}       
                  </div>
                )}
              </div>
              <div className={`container-buttons col-width-${numOfColumns}`}>
                <div className="match-center-buttons">
                  <a
                    className={`button wi-buy-button wi-buy-button-info wi-match-result-centre-button col-width-${numOfColumns}`}
                    href={`${
                      window.MATCH_CENTRE_WEBSITE_BASE_URL
                    }/match/${gid}`}
                  >
                    Match Centre
                  </a>
                </div>
              </div>
            </div>
                
          </div>
            
        </Column>
          
      </>
    );
  }
}
