export enum MatchStatus {
  COMPLETE = 'complete',
  LIVE = 'live',
  FORTHCOMING = 'forthcoming',
}

export interface ProductMeta {
  description_long: null | string;
  description_short: null | string;
  product: string;
  product_name: string;
  title_long: string;
  title_short: string;
}

export interface Tooltips {
  product_meta: ReadonlyArray<ProductMeta>;
}

export interface Weather {
  icon_black: string;
  icon_white: string;
  temperature: number;
  link: string;
}
