import { MatchStatus } from '^/match-centre/summary-panel/types';
import { ReactChild, ReactElement } from 'react';

export enum Batter {
  STRIKER = 'striker',
  NONSTRIKER = 'nonstriker',
}

export enum BattedStatus {
  NOT_BATTED = 'DNB',
  HAS_BATTED = 'yes',
}

export enum BatterStatus {
  ON_PITCH = '0',
  NOT_ON_PITCH = '1',
}

export enum BattingHandAcronym {
  LEFT = 'LHB',
  RIGHT = 'RHB',
}

export enum BattingHand {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum BowlingStatus {
  CURRENT = 'current',
  PREVIOUS = 'previous',
}

export enum DismissalType {
  CAUGHT_OUT = 'caught',
  BOWLED_OUT = 'bowled',
  LEG_BEFORE_WICKET = 'leg before wicket',
  NOT_OUT = 'not out',
}

export interface Award {
  player_id: string;
  type_name: string;
}

export interface Fielding {
  is_keeper: string;
  is_sub: string;
  player_id: string;
}

export interface Batting {
  balls_faced: string;
  batted: string;
  batting_position: string;
  bowling_player_id: string;
  dismissal_name: string;
  dismissal_str: string;
  dots_faced: string;
  fielding: ReadonlyArray<Fielding>;
  fours: string;
  is_out: string;
  live_status: string;
  minutes: null | string;
  player_id: string;
  runs: string;
  sixes: string;
  xR_diff?: string | null | undefined;
}

export interface Bowling {
  bowling_position: string;
  conceded: string;
  dots: string;
  fours_conceded: string;
  live_status: string;
  maidens: string;
  noballs: string;
  overs: string;
  player_id: string;
  sixes_conceded: string;
  wickets: string;
  wides: string;
  xw_rating?: string | null | undefined;
}

export interface BowlingType {
  balls: string;
  bowling_type: string;
  conceded: string;
  wickets: string;
}

export interface Fow {
  fow_order: string;
  fow_overs: string;
  fow_runs: string;
  fow_type: string;
  fow_wickets: string;
  out_player_id: string;
  partnership_overs: string;
  partnership_runs: string;
  partnership_wicket: string;
  player1_id: string;
  player2_id: string;
}

export interface ConstructedFow extends Fow {
  dismissed: string;
  partner: string;
}

export interface Innings {
  batted: string;
  batting: ReadonlyArray<Batting>;
  batting_team_id: string;
  bowling: ReadonlyArray<Bowling>;
  bowling_team_id: string;
  bowling_type: ReadonlyArray<BowlingType>;
  bpo: string;
  byes: string;
  closure_name: string;
  extras: string;
  fow: ReadonlyArray<Fow>;
  innings_number: string;
  actual_innings_number: string;
  innings_penalty_bonus: string;
  lead: string;
  legbyes: string;
  live_status: string;
  minutes: null | string;
  noballs: string;
  over_limit: string;
  overs: string;
  penalties: string;
  retirement: ReadonlyArray<Record<string, string>>;
  runs: string;
  target: string;
  wickets: string;
  wides: string;
}

export interface Class {
  id: string;
  name: string;
  number: string;
  type: string;
  valid: string;
}

export interface Player {
  batting_hand: string;
  batting: string;
  batting_team_id: string;
  bowling: string;
  innings_number: string;
  player_id: string;
  name: string;
  known_as: string;
  role_icon: string;
  player_role: string;
  is_captain: string;
  is_keeper: string;
  bowling_desc: string;
  bowling_stock: string;
}

export interface TeamSummary {
  score: string;
  team_id: string;
}

export interface Summary {
  player: ReadonlyArray<Player>;
  team: ReadonlyArray<TeamSummary>;
}

export interface MatchWeather {
  city: string;
}

export interface Match {
  batting_first_team_id: string;
  class: ReadonlyArray<Class>;
  comp_id: string;
  comp_name: string;
  comp_season: string;
  comp_type: string;
  country_id: string;
  country_name: string;
  date_string: string;
  end_date: string;
  floodlit: string;
  gid: string;
  ground_id: string;
  ground_name: string;
  ground_short_name: string;
  ground_time_zone: string;
  is_adjusted_target: null | string;
  is_followon: string;
  is_reduced_innings: string;
  is_reduced_overs: string;
  live_status_name: string;
  margin: string;
  margin_balls: string;
  margin_name: string;
  match_id: string;
  match_situation_str: string;
  match_situation_str_long: string;
  match_status: MatchStatus;
  name: string;
  rain_rule: string;
  result_name: string;
  result_string: string;
  scheduled_days: string;
  scheduled_innings: string;
  scheduled_overs: string;
  season: string;
  start_date: string;
  start_datetime_utc: string;
  start_time_local: string;
  stream_provider_url: null | string;
  summary: Summary;
  team1_abbreviation: string;
  team1_colour_primary: string;
  team1_colour_secondary: string;
  team1_id: string;
  team1_logo_url: null | string;
  team1_name: string;
  team1_thumb_url: null | string;
  team2_abbreviation: string;
  team2_colour_primary: string;
  team2_colour_secondary: string;
  team2_id: string;
  team2_logo_url: null | string;
  team2_name: string;
  team2_thumb_url: null | string;
  ticket_provider_url: null | string;
  tiebreaker_name: string;
  tiebreaker_team_id: string;
  title: string;
  toss_choice_team_id: string;
  toss_decision: string;
  toss_winner_team_id: string;
  winner_team_id: string;
  weather: MatchWeather;
}

export interface Official {
  country_id: string;
  country_name: null | string;
  known_as: string;
  name: string;
  player_id: string;
  type_name: string;
}

export interface Session {
  closure_name: string;
  day_number: string;
  innings_number: string;
  is_day_end: string;
  is_session_end: string;
  overs: string;
  player1_id: string;
  player1_runs: string;
  player2_id: string;
  player2_runs: string;
  runs: string;
  session_number: string;
  status_name: string;
  time: string;
  wickets: string;
}

export interface Team {
  abbreviation: string;
  batsmen_in_side: string;
  name: string;
  player: ReadonlyArray<Player>;
  players_in_side: string;
  short_name: string;
  team_id: string;
}

export interface InningsTeamInfo {
  logo_url: string | null;
  name: string | null;
}

export interface Winviz {
  draw_percent: string;
  prediction_innings_number: string;
  prediction_runs: string;
  team1_notie_percent: string;
  team1_percent: string;
  team2_notie_percent: string;
  team2_percent: string;
  tie_percent: string;
}

export interface Pitchviz {
  bounce: string;
  bounce_consistency: string;
  bowling_type: string;
  deviation_pace: string;
  deviation_spin: string;
  innings_number: number;
  overs_unique: string;
  pitchviz_overall: string;
  pitchviz_trend: string;
  swing_pace: string;
  swing_spin: string;
}

export interface Scorecard {
  award: ReadonlyArray<Award>;
  debuts: ReadonlyArray<Record<string, string>>;
  innings: ReadonlyArray<Innings>;
  match: Match;
  official: ReadonlyArray<Official>;
  pitchviz: ReadonlyArray<Pitchviz>;
  session: ReadonlyArray<Session>;
  team: ReadonlyArray<Team>;
  winviz: Winviz;
}

export interface FlexTableColumn<T> {
  header: string | ReactElement;
  value: (item: T) => ReactChild;
  total?: ReactChild;
}
