const sidebar = document.getElementById('wi-side-bar');
const navbarIcon = document.getElementById('navbar-icon');
const sideBarOverlay = document.getElementById('wi-side-bar-overlay');
const allSideBarItems = [...document.querySelectorAll('.clickable-sidebar')];

let timeout;

const ACTIVE_CLASS_NAME = 'active';
const DISPLAY_CLASS_NAME = 'display-none';

function closeNavbar() {
  clearTimeout(timeout);

  sidebar.classList.remove(ACTIVE_CLASS_NAME);
  sideBarOverlay.classList.remove(ACTIVE_CLASS_NAME);

  timeout = setTimeout(function() {
    sideBarOverlay.classList.add(DISPLAY_CLASS_NAME);
  }, 250);
}

function openNavbar() {
  clearTimeout(timeout);
  sideBarOverlay.classList.remove(DISPLAY_CLASS_NAME);

  timeout = setTimeout(function() {
    sidebar.classList.add(ACTIVE_CLASS_NAME);
    sideBarOverlay.classList.add(ACTIVE_CLASS_NAME);
  }, 50);
}

navbarIcon.addEventListener('click', () => {
  if (sidebar.classList.contains(ACTIVE_CLASS_NAME)) {
    closeNavbar();
  } else {
    openNavbar();
  }
});

sideBarOverlay.addEventListener('click', () => {
  closeNavbar();
});

allSideBarItems.forEach(item => {
  item.addEventListener('click', () => {
    if (sidebar.classList.contains(ACTIVE_CLASS_NAME)) {
      window.setTimeout(() => {
        closeNavbar();
      }, 200);
    }
  });
});

sideBarOverlay.ontouchmove = function(event) {
  event.preventDefault();
};

sidebar.ontouchmove = function(event) {
  event.preventDefault();
};
