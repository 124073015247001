import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import WiPlayerCard from './wi-player-card';
import { Row } from '@dabapps/roe';

class InfiniteScrollingPlayersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      params: null,
      currentPage: 1,
      nextPage: 1,
      lastPage: 1,
      isLoading: true,
    };
  }

  componentWillMount() {
    this.initialLoading();
  }

  componentDidMount() {
    this.loadPlayers();
  }

  initialLoading = () => {
    const { params } = this.props;

    this.setState({
      params: {
        type: params.type,
        value: params.value,
      },
    });
  };

  loadPlayers = () => {
    const { params, nextPage } = this.state;

    axios
      .get(
        `/api/player-list/?filter_type=${params.type}&filter_value=${
          params.value
        }&page=${nextPage}`
      )
      .then(response => {
        this.setState({
          items: [...this.state.items, ...response.data.results],
          currentPage: response.data.page.current_page,
          nextPage: response.data.page.next_page,
          lastPage: response.data.page.last_page,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({ isLoading: true });
      });
  };

  render() {
    const { currentPage, lastPage, items, isLoading } = this.state;

    return (
      <Row className="wi-player-list">
        <InfiniteScroll
          pageStart={1}
          loadMore={this.loadPlayers}
          hasMore={currentPage !== lastPage}
          loader={
            <div key={0} className="wi-player-loader wi-player-loading">
              Loading...
            </div>
          }
        >
          {isLoading && (
            <div className="column wi-player-loader">Loading...</div>
          )}
          {items.length > 0 &&
            items.map((item, index) => (
              <WiPlayerCard
                key={index}
                name={item.known_as}
                link={item.url}
                image={item.image}
                flag={item.flag_pob_thumb_url}
              />
            ))}
        </InfiniteScroll>
      </Row>
    );
  }
}

InfiniteScrollingPlayersList.propTypes = {
  params: PropTypes.object,
};

window.renderInfiniteScrollingPlayersList = (element, params) => {
  ReactDOM.render(<InfiniteScrollingPlayersList params={params} />, element);
};
