const allVideoPlayInputs = [
  ...document.querySelectorAll('.wi-video-play-input'),
];

if (allVideoPlayInputs.length) {
  allVideoPlayInputs.forEach(item => {
    item.addEventListener('change', () => {
      if (!item.checked) {
        const videos = [...item.parentNode.getElementsByTagName('video')];
        if (videos.length) {
          videos.forEach(videonode => {
            videonode.pause();
          });
        }
      }
    });
  });
}
