import { ResponsesReducerState } from '@dabapps/redux-requests';
import { ThunkAction } from 'redux-thunk';

export interface StoreState {
  allMatches: MatchCentreState | null;
  responses?: ResponsesReducerState;
}

export interface GenericObject {
  [key: string]: string;
}

export interface Action<Payload, Meta> {
  type: string;
  payload?: Payload;
  error?: boolean;
  meta?: Meta;
}

export type ActionNoPayload = Action<undefined, undefined>;

export type ThunkActionOrAction =
  | Action<any, any>
  | ThunkAction<any, any, undefined, any>;

export type StringPropertyNames<T> = {
  [K in keyof T]: T[K] extends string ? K : never
}[keyof T];

export interface Weather {
  icon_black: string;
  icon_white: string;
  provider_id: number;
  source: string;
  temperature: number | null;
  link: string;
  city: string;
}

export interface Class {
  id: string;
  name: string;
  number: string;
  type: string;
  valid: string;
}

export enum BattingHand {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum BowlingStatus {
  CURRENT = 'current',
  PREVIOUS = 'previous',
}

export enum BattedStatus {
  NOT_BATTED = 'DNB',
  HAS_BATTED = 'yes',
}
export enum MatchStatus {
  COMPLETE = 'complete',
  LIVE = 'live',
  FORTHCOMING = 'forthcoming',
}

export interface Innings {
  batted: string;
  bpo: string;
  closure_name: string;
  innings_number: string;
  lead: string;
  match_id: string;
  over_limit: string;
  overs: string;
  result_id: string;
  result_name: string;
  runs: string;
  summary_batting: string;
  summary_bowling: string;
  target: string;
  team_id: string;
  team_name: string;
  team_short_name: string;
  toss: string;
  wickets: string;
  live_status: string;
}

export interface MatchBase {
  title: string;
  name: string;
  start_date: string;
  start_date_ast: string;
  start_time_ast: string;
  team1_id: string;
  team1_logo_url: null | string | undefined;
  team1_name: string;
  team2_id: string;
  team2_logo_url: null | string | undefined;
  team2_name: string;
  ground_id: string;
  ground_name: string;
  general_class_id: string;
  general_class_name: string;
  match_id: string;
  match_status: MatchStatus;
  start_time_local: string;
  weather: Weather;
  ground_url: string | null;
  start_day: string;
  gid: string;
}

export interface LiveMatch extends MatchBase {
  comp_id: string;
  comp_name: string;
  comp_season: string;
  comp_type: string;
  country_id: string;
  country_name: string;
  date_string: string;
  end_date: string;
  floodlit: string;
  general_class_number: string;
  general_class_valid: string;
  ground_time_zone: string;
  live_status: string;
  live_status_id: string;
  margin: string;
  margin_balls: string;
  margin_name: string;
  rain_rule: string;
  result_id: string;
  result_match_name: string;
  result_name: string;
  scheduled_days: string;
  scheduled_innings: string;
  innings: ReadonlyArray<Innings>;
  scheduled_overs: string;
  season: string;
  start_datetime_utc: string;
  team1_abbreviation: string;
  team1_colour_primary: string;
  team1_colour_secondary: string;
  team1_thumb_url: null | string;
  team2_abbreviation: string;
  team2_colour_primary: string;
  team2_colour_secondary: string;
  team2_thumb_url: null | string;
  ticket_provider_url: null | string;
  tiebreaker_name: string;
  tiebreaker_team_id: string;
  title: string;
  winner_team_id: string;
  toss_decision: string;
  result_string: string | null;
  gid: string;
}

export interface UpcomingMatch extends MatchBase {
  international_class_id: string | null;
  international_class_name: string | null;
  start_time_utc: string;
}

export interface Match {
  live: ReadonlyArray<LiveMatch> | null;
  complete: ReadonlyArray<LiveMatch> | null;
  forthcoming: ReadonlyArray<UpcomingMatch> | null;
}

export interface UpstreamData {
  match: Match;
}

export interface MatchCentreState {
  poll_interval: number;
  upstream_data: UpstreamData;
  liveMatches?: LiveMatch[];
  upcomingMatches?: GroupedUpcomingData[];
  recentMatches?: GroupedRecentData[];
}

export interface GroupedUpcomingData {
  match_date: string;
  matches: ReadonlyArray<UpcomingMatch>;
}

export interface GroupedRecentData {
  match_date: string;
  matches: ReadonlyArray<LiveMatch>;
}
