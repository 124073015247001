import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hasArenaId, isMatchLive } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import Tab from '^/match-centre/tabs-panel/tab';
import { TabItems } from '^/match-centre/tabs-panel/types';

import ArenaBlog from '^/match-centre/arena-blog';
import Articles from '^/match-centre/articles';
import BallByBall from '^/match-centre/bbb';
import Players from '^/match-centre/players';
import ScoreCard from '^/match-centre/scorecard';

export interface StateProps {
  isLive?: boolean;
  hasArena?: boolean;
}

export type Props = StateProps;

interface State {
  selectedTabPath: string | null;
}

const MATCHES_LEADING_HASH = /^#/;

const getCleanHash = (): string | null =>
  window.location.hash.replace(MATCHES_LEADING_HASH, '') || null;

export class TabsPanel extends PureComponent<Props, State> {
  public state: State;

  public constructor(props: Props) {
    super(props);

    this.state = {
      selectedTabPath: getCleanHash(),
    };
  }

  public componentDidMount() {
    window.addEventListener('hashchange', this.onHashChange);

    if (!this.state.selectedTabPath) {
      window.location.hash = 'scorecard';
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange);
  }

  public render() {
    const { isLive, hasArena } = this.props;
    const { selectedTabPath } = this.state;

    const tabItems: TabItems = [
      {
        name: `${isLive ? 'Live ' : ''}BBB`,
        path: 'bbb',
        component: BallByBall,
      },
      {
        name: 'Scorecard',
        path: 'scorecard',
        component: ScoreCard,
      },
      {
        name: 'Articles',
        path: 'articles',
        component: Articles,
      },
      {
        name: 'Players',
        path: 'players',
        component: Players,
      },
    ];

    if (hasArena) {
      tabItems.unshift({
        name: 'Live Blog',
        path: 'liveblog',
        component: ArenaBlog,
      });
    }

    const selectedTab = tabItems.find(tab => tab.path === selectedTabPath);
    const SelectedTabComponent = selectedTab && selectedTab.component;

    return (
      <>
        <div className="wi-match-tabs">
          {tabItems.map(tab => (
            <Tab key={tab.path} tab={tab} selectedTabPath={selectedTabPath} />
          ))}
        </div>

        {SelectedTabComponent && <SelectedTabComponent />}
      </>
    );
  }

  private onHashChange = () => {
    this.setState({
      selectedTabPath: getCleanHash(),
    });
  };
}

export const mapStateToProps = (state: StoreState): StateProps => ({
  isLive: isMatchLive(state),
  hasArena: hasArenaId(state),
});

export default connect(mapStateToProps)(TabsPanel);
