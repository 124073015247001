import React from 'react';
import { connect } from 'react-redux';

import { Player } from '^/match-centre/scorecard/types';
import { playerPageSelector, playerSelector } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';

export interface OwnProps {
  playerId: string;
}

export interface StateProps {
  player: Player | undefined;
  playerPage: string | undefined;
}

export type Props = OwnProps & StateProps;

export const BowlerDetails = ({ player, playerPage }: Props) => {
  if (!player) {
    return null;
  }
  return player.bowling_desc ? (
    <>
      {playerPage ? (
        <div className="top">
          <a href={playerPage} target="_blank" className="wi-player-cell-link">
            {player.known_as}
          </a>
        </div>
      ) : (
        <div className="top">{player.known_as}</div>
      )}
      <div className="bottom">{player.bowling_desc}</div>
    </>
  ) : (
    <>{player.known_as}</>
  );
};

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  player: playerSelector(state, props.playerId),
  playerPage: playerPageSelector(state, props.playerId),
});

export default connect(mapStateToProps)(React.memo(BowlerDetails));
