export interface Predictviz {
  innings_number: string;
  overs: string;
  runs: string;
  team_id: string;
  wickets: string;
}

export enum TeamReview {
  BATTING = 'batting',
  BOWLING = 'fielding',
  REVIEW = 'R',
}

export enum ExtrasBBB {
  NO_BALLS = 'nb',
  WIDES = 'wd',
  BYES = 'b',
  LEGBYES = 'lb',
}

export interface PredictvizMargin {
  innings: null | string;
  result: string;
  runs: string;
  wickets: string | null;
  winner_team_id: string;
}

export interface Winviz {
  batting_team_percent: string;
  bowling_team_percent: string;
  draw_percent: string | null;
  tie_percent: string;
}

export interface Ball {
  ball_archetype: null | string;
  ball_detail: string;
  ball_id: string;
  ball_length: string;
  ball_line: string;
  ball_number: string;
  ball_speed: string;
  batsman_conn: string;
  batsman_foot: string | null;
  batsman_shot: string;
  batting_player_id: string;
  batting_team_id: string;
  bowling_player_id: string;
  bowling_team_id: string;
  byes: string;
  datetime_utc: string;
  field_direction: string;
  field_magnitude: string;
  fielding_player_id: string | null;
  fielding_position: string | null;
  free_hit: string;
  innings_number: string;
  is_boundary: string;
  is_out: string | null;
  legal_ball_number: string;
  legbyes: string;
  match_id: string;
  noballs: string;
  nonstriker_player_id: string;
  out_player_id: string;
  over_number: string;
  overs_unique: string;
  predictviz: ReadonlyArray<Predictviz>;
  predictviz_margin: PredictvizMargin;
  review_success: string;
  reviewer_team: null | string;
  runs: string;
  runs_off_bat: string;
  sort_key: string;
  wides: string;
  winviz: Winviz;
  dismissal_str: string;
}

export interface Page {
  current_page: null | number;
  first_page: null | number;
  last_page: null | number;
  next_page: null | number;
  page_size: null | number;
  previous_page: null | number;
  total_rows: null | number;
}

export interface BBB {
  ball: ReadonlyArray<Ball>;
  page: Page;
}

export interface ModifiedBallsRecord {
  over: string;
  balls: ReadonlyArray<Ball>;
}
