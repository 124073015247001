import classnames from 'classnames';
import React from 'react';

export interface Props {
  className?: string;
}

export const ClockIcon = (props: Props) => (
  <svg
    width="141.732"
    height="141.732"
    viewBox="0 0 141.732 141.732"
    xmlns="http://www.w3.org/2000/svg"
    className={classnames('clock-icon', props.className)}
  >
    <path d="m113.326,70.191c0,-2.97 -2.377,-5.376 -5.307,-5.376l-32.476,0l0,-32.428l0,-0.001c0,-2.98 -2.418,-5.397 -5.396,-5.397c-2.98,0 -5.398,2.417 -5.398,5.397l0,37.784c0,2.981 2.418,5.398 5.398,5.398l37.875,0c2.927,0 5.304,-2.407 5.304,-5.377m16.207,-0.034c0,32.798 -26.584,59.386 -59.375,59.386c-32.793,0 -59.375,-26.588 -59.375,-59.386s26.582,-59.386 59.375,-59.386c32.791,0 59.375,26.588 59.375,59.386m10.781,0c0,-38.747 -31.41,-70.157 -70.156,-70.157c-38.748,0 -70.158,31.41 -70.158,70.157s31.41,70.157 70.158,70.157c38.746,0 70.156,-31.41 70.156,-70.157" />
  </svg>
);

export default React.memo(ClockIcon);
