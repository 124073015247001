import React from 'react';

const WiPlayerPlaceholder = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="113"
    viewBox="0 0 80 113"
  >
    <g fill="#660028" fillRule="evenodd">
      <rect width="36" height="48" x="22" rx="18" />
      <rect width="20" height="15" x="30" y="39" />
      <path d="M14.4699096,57.1060181 L40,52 L65.5300904,57.1060181 C73.943726,58.7887452 80,66.1762119 80,74.7564702 L80,113 L0,113 L0,74.7564702 C-1.05077859e-15,66.1762119 6.05627403,58.7887452 14.4699096,57.1060181 Z" />
    </g>
  </svg>
);

export default WiPlayerPlaceholder;
