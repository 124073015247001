import React from 'react';
import { connect } from 'react-redux';

import { Match } from '^/match-centre/scorecard/types';
import { isMatchLive, matchSelector } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';

export interface StateProps {
  match: Match | null;
  isMatchStatusLive: boolean;
}
export type Props = StateProps;

export const StatusHeader = ({ match, isMatchStatusLive }: Props) =>
  match && (
    <div className="wi-status-header">
      {isMatchStatusLive && (
        <div className="wi-status-header-icon">
          <svg className="live-match-dot" viewBox="0 0 20 20">
            <circle
              className="live-match-dot-outer"
              stroke="#FBED00"
              stroke-width="2"
              cx="10"
              cy="10"
              r="8"
            />
            <circle
              className="live-match-dot-inner"
              fill="#FBED00"
              cx="10"
              cy="10"
              r="4"
            />
          </svg>
        </div>
      )}

      <div className="wi-status-header-content">
        <div className="wi-status-header-title">
          {isMatchStatusLive ? 'Live right now' : ''}
        </div>

        <div className="wi-status-header-text">{match.name}</div>
      </div>
    </div>
  );

const mapStateToProps = (state: StoreState): StateProps => ({
  match: matchSelector(state),
  isMatchStatusLive: isMatchLive(state),
});

export default connect(mapStateToProps)(React.memo(StatusHeader));
