import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { isMatchLive } from '^/match-centre/selectors';
import StatusHeader from '^/match-centre/status-header';
import { StoreState } from '^/match-centre/store/types';
import SummaryPanel from '^/match-centre/summary-panel';
import TablesPanel from '^/match-centre/tables-panel';
import { hasOnPitchBattersSelector } from '^/match-centre/tables-panel/selectors';
import TabsPanel from '^/match-centre/tabs-panel';

import {
  subscribePusherChannels,
  unSubscribePusherChannels,
} from '^/match-centre/scorecard/actions';

export interface OwnProps {
  pusherApiKey: string;
}

export interface StateProps {
  isMatchStatusLive: boolean;
}

export interface DispatchProps {
  subscribePusherChannels: typeof subscribePusherChannels;
  unSubscribePusherChannels: typeof unSubscribePusherChannels;
}

export type Props = OwnProps & StateProps & DispatchProps;

export class App extends PureComponent<Props> {
  public render() {
    const { isMatchStatusLive } = this.props;
    return (
      <>
        <StatusHeader />

        <div className="wi-matches-column left">
          <SummaryPanel />
          {isMatchStatusLive && <TablesPanel />}
        </div>

        <div className="wi-matches-column right">
          <TabsPanel />
        </div>
      </>
    );
  }

  public componentDidMount() {
    this.props.subscribePusherChannels(this.props.pusherApiKey);
  }

  public componentWillUnmount() {
    this.props.unSubscribePusherChannels(this.props.pusherApiKey);
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  isMatchStatusLive: isMatchLive(state) && hasOnPitchBattersSelector(state),
});

export default connect(
  mapStateToProps,
  {
    subscribePusherChannels,
    unSubscribePusherChannels,
  }
)(App);
