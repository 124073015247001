import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import * as arenaIdReducers from '^/match-centre/arena-blog/reducers';
import * as articleReducers from '^/match-centre/articles/reducers';
import * as bbbReducers from '^/match-centre/bbb/reducers';
import { ThunkActionOrAction } from '^/match-centre/common/types';
import * as infoTooltips from '^/match-centre/info-button/reducers';
import * as playersReducers from '^/match-centre/players/reducers';
import * as scorecardReducers from '^/match-centre/scorecard/reducers';
import { StoreState } from '^/match-centre/store/types';
import * as tooltipsReducers from '^/match-centre/summary-panel/reducers';

import pusherMiddleware from '^/match-centre/middleware';

const rootReducer = combineReducers<StoreState, AnyAction>({
  ...bbbReducers,
  ...scorecardReducers,
  ...tooltipsReducers,
  ...playersReducers,
  ...articleReducers,
  ...infoTooltips,
  ...arenaIdReducers,
});

const middleware: Array<Middleware<{}, StoreState>> = [
  thunk,
  pusherMiddleware,
  <S>(api: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => (
    action: ThunkActionOrAction
  ) =>
    typeof action === 'function'
      ? next(action(api.dispatch, api.getState, undefined))
      : next(action),
];

export default function prepopulateStore(initialState: Partial<StoreState>) {
  return createStore<StoreState, AnyAction, {}, {}>(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
