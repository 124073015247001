import { Badge } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';

import { Player } from '^/match-centre/scorecard/types';
import { StoreState } from '^/match-centre/store/types';

import { playerPageSelector } from '^/match-centre/selectors';

const ROLE_IMAGE_API_BASE = 'https://img.cricviz.com/icons/role/';

export interface StateProps {
  playerPage: string | undefined;
}

export interface OwnProps {
  player: Player;
}

export type Props = OwnProps & StateProps;

export const PlayerCell = ({ player, playerPage }: Props) => {
  const isCaptain = Boolean(parseInt(player.is_captain, 10));
  const isWicketKeeper = Boolean(parseInt(player.is_keeper, 10));
  return (
    <div className="wi-player-cell">
      <img
        className="wi-player-role-icon"
        src={ROLE_IMAGE_API_BASE + player.role_icon}
      />
      {playerPage ? (
        <a href={playerPage} target="_blank" className="wi-player-cell-link">
          <div className="player-cell-name">{player.name}</div>
        </a>
      ) : (
        <div className="wi-player-cell-name">{player.name}</div>
      )}
      {isCaptain && <Badge className="wi-player-cell-badge captain">C</Badge>}
      {isWicketKeeper && (
        <Badge className="wi-player-cell-badge wicket-keeper">WK</Badge>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  playerPage: playerPageSelector(state, props.player.player_id),
});

export default connect(mapStateToProps)(React.memo(PlayerCell));
