import React from 'react';

export interface StateProps {
  text: string;
}

export type Props = StateProps;

export const NoDataMessage = ({ text }: Props) => (
  <p className="wi-no-data-message">{text}</p>
);

export default React.memo(NoDataMessage);
