import React from 'react';

import { ProductMeta } from '^/match-centre/summary-panel/types';

export interface Props {
  meta: ReadonlyArray<ProductMeta>;
}

export const InfoTooltipContent = ({ meta }: Props) => (
  <>
    {meta.map((item: ProductMeta, index: number) => (
      <React.Fragment key={index}>
        <p className="wi-info-tooltip-content bold">
          {item.title_long ? item.title_long : 'N/A'}
        </p>
        <p className="wi-info-tooltip-content">
          {item.description_long ? item.description_long : 'N/A'}
        </p>
        {index !== meta.length - 1 && (
          <div className="wi-info-tooltip-content-seperator" />
        )}
      </React.Fragment>
    ))}
  </>
);

export default React.memo(InfoTooltipContent);
