import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '^/match-centre/store/types';

import { ArenaId } from '^/match-centre/arena-blog/types';
import { arenaIdSelector } from '^/match-centre/selectors';

export interface StateProps {
  arenaId: ArenaId;
}

const ARENA_SCRIPT_SRC =
  'https://go.arena.im/public/js/arenalib.js?p=cricket-west-indies&event=';

type Props = StateProps;

export class ArenaBlog extends PureComponent<Props> {
  public componentDidMount() {
    this.loadScriptWithArenaId();
  }

  public shouldComponentUpdate() {
    // Prevent react from controlling elements that the external script will want to control
    return false;
  }

  public render() {
    return (
      // Additional div wrapper ensures that elements generated by the external script
      // (outside of the "arena-live" one we give it) will be destroyed on unmount
      <div>
        <div
          id="arena-live"
          data-publisher="cricket-west-indies"
          data-event={this.props.arenaId}
          data-version="2"
        />
      </div>
    );
  }

  private loadScriptWithArenaId() {
    if (this.props.arenaId) {
      if (window.arenaLiveblog) {
        window.arenaLiveblog.reset();
      } else {
        const script = document.createElement('script');
        script.src = `${ARENA_SCRIPT_SRC}${this.props.arenaId}`;
        document.body.appendChild(script);
      }
    }
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  arenaId: arenaIdSelector(state),
});

export default connect(mapStateToProps)(ArenaBlog);
