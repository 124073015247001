import { Score } from '^/match-centre-dashboard/score';
import { getTeamInnings } from '^/match-centre-dashboard/selectors';
import { LiveMatch } from '^/match-centre-dashboard/store/types';
import React, { PureComponent } from 'react';
import { LogoBadge } from '../common/logo-badge';
import { MapIcon } from '../common/map-icon';
import { NextIcon } from '../common/next-icon';

export interface StateProps {
  match: LiveMatch;
}

export class RecentMatches extends PureComponent<StateProps> {
  public render() {
    const {
      team1_id,
      team2_id,
      team1_logo_url,
      team1_name,
      team2_logo_url,
      team2_name,
      innings,
      ground_name,
      ground_url,
      gid,
      title,
      result_string,
    } = this.props.match;
    return (
      <>
        <div className="wi-match-result">
          <div className="wi-match-result-info">
            <span className="wi-match-result-info-name">{title}</span>
            {result_string && (
              <span className="wi-match-result-info-result">
                {result_string}
              </span>
            )}
            {ground_url && ground_name && (
              <div className="wi-match-result-info-location">
                <MapIcon />
                <a href={ground_url}>{ground_name}</a>
              </div>
            )}
            {!ground_url && ground_name && (
              <div className="wi-fixture-ground">{ground_name}</div>
            )}
          </div>
          <div className="wi-match-result-teams">
            <div className="wi-match-result-team">
              <div>
                <div className="wi-match-result-team-name first">
                  {team1_name}
                </div>
                <div className="wi-match-result-team-detail">
                  <Score
                    isMatchStatusComplete
                    innings={getTeamInnings(innings, team1_id)}
                  />
                </div>
              </div>
              <div className="wi-match-result-team-badge">
                {team1_logo_url && (
                  <img
                    className="wi-fixture-team-badge-img"
                    src={team1_logo_url}
                    alt="Team badge"
                  />
                )}
                {!team1_logo_url && (
                  <LogoBadge className="wi-fixture-team-badge-img" />
                )}
              </div>
            </div>
            <div className="wi-match-result-vs">vs</div>
            <div className="wi-match-result-team">
              <div className="wi-match-result-team-badge">
                {team2_logo_url && (
                  <img
                    className="wi-fixture-team-badge-img"
                    src={team2_logo_url}
                    alt="Team badge"
                  />
                )}
                {!team2_logo_url && (
                  <LogoBadge className="wi-fixture-team-badge-img" />
                )}
              </div>
              <div>
                <div className="wi-match-result-team-name last">
                  {team2_name}
                </div>
                <div className="wi-match-result-team-detail">
                  <Score
                    isMatchStatusComplete
                    innings={getTeamInnings(innings, team2_id)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="wi-match-result-buttons">
            <a
              className="button wi-button wi-match-result-centre-button"
              href={`${window.MATCH_CENTRE_WEBSITE_BASE_URL}/match/${gid}`}
            >
              MATCH CENTRE <NextIcon className="wi-next-icon" />
            </a>
          </div>
        </div>
      </>
    );
  }
}
