const allCheckboxes = [...document.querySelectorAll('.wi-filter-by-input')];

const ACTIVE_CLASS_NAME = 'active';

allCheckboxes.forEach(item => {
  item.addEventListener('click', () => {
    if (!item.classList.contains(ACTIVE_CLASS_NAME)) {
      allCheckboxes.forEach(otherItems => {
        otherItems.classList.remove(ACTIVE_CLASS_NAME);
        otherItems.checked = false;
      });
      item.classList.add(ACTIVE_CLASS_NAME);
      item.checked = true;
    } else {
      item.classList.remove(ACTIVE_CLASS_NAME);
      item.checked = false;
    }
  });
});
