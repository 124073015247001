import {
  BattedStatus,
  BowlingStatus,
  Innings,
} from '^/match-centre-dashboard/store/types';
import React, { FunctionComponent } from 'react';
const MAX_WICKETS = 10;

interface Props {
  inning: Innings;
  isMatchStatusComplete?: boolean;
}

export const Overs: FunctionComponent<Props> = ({
  inning,
  isMatchStatusComplete,
}) => {
  const overLimitNumber = parseFloat(inning.over_limit);
  const oversNumber = parseFloat(inning.overs);

  if (!overLimitNumber && !oversNumber) {
    return null;
  }

  const showResultLiveString = Boolean(
    inning.batted &&
      inning.batted !== BattedStatus.NOT_BATTED &&
      !isMatchStatusComplete
  );

  return showResultLiveString ? (
    <span className="wi-overs-results">
      {' '}
      ({Boolean(oversNumber) && inning.overs}
      {oversNumber && overLimitNumber ? ' / ' : null}
      {Boolean(overLimitNumber) && inning.over_limit})
    </span>
  ) : null;
};

export const RunsAndWickets: FunctionComponent<Props> = ({
  inning,
  isMatchStatusComplete,
}) => {
  const wicketsNumber = parseFloat(inning.wickets);

  const runs = inning.runs !== '0' ? inning.runs : '';
  const showWickets = wicketsNumber < MAX_WICKETS;
  const wickets = showWickets ? `/${inning.wickets}` : '';
  const liveStatus =
    inning.live_status === BowlingStatus.CURRENT && !isMatchStatusComplete
      ? '*'
      : '';
  const showResultString = Boolean(
    inning.batted && inning.batted !== BattedStatus.NOT_BATTED
  );

  return showResultString ? (
    <>
      {runs}
      {wickets}
      {liveStatus}
    </>
  ) : null;
};

interface RunsWicketAndOversOwnProps {
  innings: ReadonlyArray<Innings>;
  isMatchStatusComplete?: boolean;
}

export const RunsWicketAndOvers: FunctionComponent<
  RunsWicketAndOversOwnProps
> = ({ innings: [inningPosition], isMatchStatusComplete }) => (
  <>
    <RunsAndWickets
      inning={inningPosition}
      isMatchStatusComplete={isMatchStatusComplete}
    />
    <Overs
      inning={inningPosition}
      isMatchStatusComplete={isMatchStatusComplete}
    />
  </>
);

interface ScoreOwnProps {
  innings: ReadonlyArray<Innings>;
}

interface ScoreStateProps {
  isMatchStatusComplete?: boolean;
}

type ScoreProps = ScoreOwnProps & ScoreStateProps;

export const Score: FunctionComponent<ScoreProps> = ({
  innings: [firstInning, secondInning],
  isMatchStatusComplete,
}) => (
  <>
    {firstInning && (
      <>
        {isMatchStatusComplete ? (
          <RunsWicketAndOvers
            innings={[firstInning]}
            isMatchStatusComplete={isMatchStatusComplete}
          />
        ) : (
          <div className="wi-score-result">
            <RunsWicketAndOvers
              innings={[firstInning]}
              isMatchStatusComplete={isMatchStatusComplete}
            />
          </div>
        )}
      </>
    )}
    {secondInning && (
      <>
        {isMatchStatusComplete ? (
          <>
            {' & '}
            <RunsWicketAndOvers
              innings={[secondInning]}
              isMatchStatusComplete={isMatchStatusComplete}
            />
          </>
        ) : (
          <>
            <div className="wi-score-result-amp">{' & '}</div>
            <div className="wi-score-result">
              <RunsWicketAndOvers
                innings={[secondInning]}
                isMatchStatusComplete={isMatchStatusComplete}
              />
            </div>
          </>
        )}
      </>
    )}
  </>
);
