const WI_CAROUSEL_SPEED = 5000;
const ACTIVE_CLASS_NAME = 'active';

let selectedCarouselRadioButtons = 0;
let carouselPaused = false;
const allCarouselRadioButtons = [
  ...document.querySelectorAll('.wi-carousel-input'),
];
const wiCarousel = [...document.querySelectorAll('.wi-carousel')];
const WI_CAROUSEL_LENGTH = allCarouselRadioButtons.length - 1;

if (allCarouselRadioButtons.length) {
  setInterval(() => {
    if (!carouselPaused) {
      allCarouselRadioButtons.forEach(item => {
        if (item.checked) {
          item.classList.remove(ACTIVE_CLASS_NAME);
          item.checked = false;
        }
      });

      allCarouselRadioButtons[selectedCarouselRadioButtons].checked = true;
      allCarouselRadioButtons[selectedCarouselRadioButtons].classList.add(
        ACTIVE_CLASS_NAME
      );

      if (selectedCarouselRadioButtons === WI_CAROUSEL_LENGTH) {
        selectedCarouselRadioButtons = 0;
      } else {
        selectedCarouselRadioButtons = selectedCarouselRadioButtons + 1;
      }
    }
  }, WI_CAROUSEL_SPEED);

  wiCarousel.forEach(item => {
    item.addEventListener('mouseover', () => {
      carouselPaused = true;
    });

    item.addEventListener('mouseout', () => {
      carouselPaused = false;
    });
  });
}
