import React, { PureComponent } from 'react';
import { ClockIcon } from '../common/clock-icon';
import { LogoBadge } from '../common/logo-badge';
import { MapIcon } from '../common/map-icon';
import NextIcon from '../common/next-icon';
import { UpcomingMatch } from '../store/types';

export interface StateProps {
  match: UpcomingMatch;
}

export class UpcomingMatches extends PureComponent<StateProps> {
  public render() {
    const {
      start_time_utc,
      start_time_ast,
      title,
      ground_name,
      ground_url,
      team1_logo_url,
      team1_name,
      team2_logo_url,
      team2_name,
      weather,
      gid,
    } = this.props.match;
    return (
      <>
        <div className="wi-match-result">
          <div className="wi-match-result-info">
            <span className="wi-match-result-info-name">{title}</span>
            <div className="wi-match-result-time">
              <ClockIcon />
              <div className="wi-match-result-time-inner">
                {start_time_ast} AST ({start_time_utc} UTC)
              </div>
            </div>
            {ground_url && ground_name && (
              <div className="wi-match-result-info-location">
                <MapIcon />
                <a
                  className="wi-match-result-ground-inner wi-link"
                  href={ground_url}
                >
                  {ground_name}
                </a>
              </div>
            )}
            {!ground_url && ground_name && (
              <div className="wi-match-result-ground">{ground_name}</div>
            )}
            {weather &&
              (weather.temperature || weather.link || weather.icon_black) && (
                <div className="row match-center-weather">
                  {weather && weather.link && (
                    <div className="column xs-12 xl-24 match-center-weather-logo">
                      <a href={weather.link} target="_blank">
                        <img
                          src="/static/accuweather_logo.png"
                          alt="Accuweather logo"
                        />
                      </a>
                    </div>
                  )}
                  {weather && weather.temperature && (
                    <div className="column xs-6 xl-7 match-center-weather-temperature">
                      <div className="float-right xl-float-left">
                        <div className="temperature-text">
                          {weather.temperature}
                        </div>
                      </div>
                    </div>
                  )}
                  {weather && weather.icon_black && (
                    <div className="column xs-6 xl-17 match-center-weather-icon">
                      <img
                        className="float-right xl-float-left"
                        src={weather.icon_black}
                        alt="Weather icon"
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
          <div className="wi-match-result-teams">
            <div className="wi-match-result-team">
              <div>
                <div className="wi-match-result-team-name first">
                  {team1_name}
                </div>
              </div>
              <div className="wi-match-result-team-badge">
                {team1_logo_url && (
                  <img
                    className="wi-fixture-team-badge-img"
                    src={team1_logo_url}
                    alt="Team badge"
                  />
                )}
                {!team1_logo_url && (
                  <LogoBadge className="wi-fixture-team-badge-img" />
                )}
              </div>
            </div>
            <div className="wi-match-result-vs">vs</div>
            <div className="wi-match-result-team">
              <div className="wi-match-result-team-badge">
                {team2_logo_url && (
                  <img
                    className="wi-fixture-team-badge-img"
                    src={team2_logo_url}
                    alt="Team badge"
                  />
                )}
                {!team2_logo_url && (
                  <LogoBadge className="wi-fixture-team-badge-img" />
                )}
              </div>
              <div>
                <div className="wi-match-result-team-name last">
                  {team2_name}
                </div>
              </div>
            </div>
          </div>
          <div className="wi-match-result-buttons">
            <a
              className="button wi-button wi-match-result-centre-button"
              href={`${window.MATCH_CENTRE_WEBSITE_BASE_URL}/match/${gid}`}
            >
              Match Centre <NextIcon className="wi-next-icon" />
            </a>
          </div>
        </div>
      </>
    );
  }
}
