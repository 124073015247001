import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { getDatesUrl } from './urls';
import _ from 'underscore';
import ReactDatesCloseButton from './react-dates-close-button';

const DATE_FORMAT = 'YYYY-MM-DD';
const noopFalse = () => false;

class DatePickerWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: props.initial_date
        ? Moment(props.initial_date, DATE_FORMAT)
        : Moment(),
      focused: false,
    };

    this.doRedirect = _.throttle(this.doRedirect, 1500);
  }

  onDateUpdate = date => {
    this.setState({ date });
    if (!date) {
      this.doRedirect.cancel();
    } else {
      this.doRedirect(date);
    }
  };

  doRedirect = date => {
    const { url_type } = this.props;
    window.location.href = date
      ? getDatesUrl(date.format(DATE_FORMAT), url_type)
      : `/${url_type}/`;
  };

  onFocusChange = ({ focused }) => {
    this.setState({ focused });
  };

  onClear = () => {
    this.doRedirect(null);
  };

  render() {
    return (
      <div>
        <SingleDatePicker
          small
          noBorder
          showDefaultInputIcon
          isOutsideRange={noopFalse}
          showClearDate
          displayFormat={DATE_FORMAT}
          date={this.state.date}
          onDateChange={this.onDateUpdate}
          focused={this.state.focused}
          onFocusChange={this.onFocusChange}
          id="wi-date-picker"
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          customCloseIcon={
            <span onClick={this.onClear}>
              <ReactDatesCloseButton />
            </span>
          }
        />
      </div>
    );
  }
}

DatePickerWidget.propTypes = {
  url_type: PropTypes.string.isRequired,
  initial_date: PropTypes.string.isRequired,
};

window.renderDatePicker = (element, url_type, initial_date) => {
  ReactDOM.render(
    <DatePickerWidget url_type={url_type} initial_date={initial_date} />,
    element
  );
};
