import { Button } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import RcTooltip from 'react-tooltip-lite';

import QuestionMarkIcon from '^/match-centre/common/questionmark-icon';
import { updateInfoTooltip } from '^/match-centre/info-button/actions';
import InfoTooltipContent from '^/match-centre/info-button/info-tooltip-content';
import { isInfoTooltipActive } from '^/match-centre/info-button/selectors';
import { StoreState } from '^/match-centre/store/types';
import { ProductMeta } from '^/match-centre/summary-panel/types';
import TooltipContent from '^/match-centre/tooltip-content';

export interface OwnProps {
  className?: string;
  tooltipClassName?: string;
  tooltipId: string;
  meta: ReadonlyArray<ProductMeta>;
  onToggle: typeof updateInfoTooltip;
  direction?: 'left' | 'right' | 'up';
}

export interface StateProps {
  isActive?: boolean;
  onToggleTooltip?: () => void;
}

export type Props = OwnProps & StateProps;

export const InfoButton = ({
  className,
  isActive,
  onToggleTooltip,
  meta,
  tooltipClassName,
  direction,
}: Props) => (
  <div className="wi-info-button-contaner">
    <RcTooltip
      direction={direction}
      forceDirection={Boolean(direction)}
      tagName="div"
      isOpen={isActive}
      className={classnames('wi-info-tooltip', direction, tooltipClassName)}
      content={
        <TooltipContent close={onToggleTooltip}>
          {meta.length ? (
            <InfoTooltipContent meta={meta} />
          ) : (
            <p className="wi-info-tooltip-content">No meta data available!</p>
          )}
        </TooltipContent>
      }
    >
      <Button
        className={classnames('wi-info-button', className)}
        onClick={onToggleTooltip}
      >
        <QuestionMarkIcon className="wi-question-mark-icon" />
      </Button>
    </RcTooltip>
  </div>
);

const mapStateToProps = (state: StoreState, props: Props): StateProps => ({
  isActive: isInfoTooltipActive(state, props.tooltipId),
  onToggleTooltip: () => props.onToggle(props.tooltipId),
});

export default connect(mapStateToProps)(React.memo(InfoButton));
