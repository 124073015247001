import {
  BattedStatus,
  BowlingStatus,
  Innings,
} from '^/match-centre/scorecard/types';
import { isMatchComplete } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

const MAX_WICKETS = 10;

interface Props {
  inning: Innings;
  isMatchStatusComplete: boolean;
}

export const Overs: FunctionComponent<Props> = ({ inning }) => {
  const overLimitNumber = parseFloat(inning.over_limit);
  const oversNumber = parseFloat(inning.overs);

  if (!overLimitNumber && !oversNumber) {
    return null;
  }

  const showResultString = Boolean(
    inning.batted && inning.batted !== BattedStatus.NOT_BATTED
  );

  return showResultString ? (
    <span className="wi-overs-results">
      {' '}
      ({Boolean(oversNumber) && inning.overs}
      {oversNumber && overLimitNumber ? ' / ' : null}
      {Boolean(overLimitNumber) && inning.over_limit})
    </span>
  ) : null;
};

export const RunsAndWickets: FunctionComponent<Props> = ({
  inning,
  isMatchStatusComplete,
}) => {
  const wicketsNumber = parseFloat(inning.wickets);

  const runs = inning.runs !== '0' ? inning.runs : '';
  const showWickets = wicketsNumber < MAX_WICKETS;
  const wickets = showWickets ? `- ${inning.wickets}` : '';
  const liveStatus =
    inning.live_status === BowlingStatus.CURRENT && !isMatchStatusComplete
      ? '*'
      : '';
  const showResultString = Boolean(
    inning.batted && inning.batted !== BattedStatus.NOT_BATTED
  );

  return showResultString ? (
    <div className="wi-score-results">
      {runs} {wickets}
      {liveStatus}
    </div>
  ) : null;
};

interface ScoreOwnProps {
  innings: ReadonlyArray<Innings>;
}

interface ScoreStateProps {
  isMatchStatusComplete: boolean;
}

type ScoreProps = ScoreOwnProps & ScoreStateProps;

export const Score: FunctionComponent<ScoreProps> = ({
  innings: [firstInning, secondInning],
  isMatchStatusComplete,
}) => (
  <>
    {firstInning && (
      <>
        <div className="wi-score-result-inner">
          <RunsAndWickets
            inning={firstInning}
            isMatchStatusComplete={isMatchStatusComplete}
          />
          <Overs
            inning={firstInning}
            isMatchStatusComplete={isMatchStatusComplete}
          />
        </div>
      </>
    )}
    {secondInning && (
      <>
        <div className="wi-score-result-amp">{' & '}</div>
        <div className="wi-score-result-inner">
          <RunsAndWickets
            inning={secondInning}
            isMatchStatusComplete={isMatchStatusComplete}
          />
          <Overs
            inning={secondInning}
            isMatchStatusComplete={isMatchStatusComplete}
          />
        </div>
      </>
    )}
  </>
);

const mapStateToProps = (state: StoreState): ScoreStateProps => ({
  isMatchStatusComplete: isMatchComplete(state),
});

export default connect(mapStateToProps)(React.memo(Score));
