import { Button } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import RcTooltip from 'react-tooltip-lite';

import BallTooltipContent from '^/match-centre/bbb/ball-tooltip-content';
import { getSelectedBall } from '^/match-centre/bbb/selectors';
import { Ball } from '^/match-centre/bbb/types';
import {
  hasNoRuns,
  isHighRuns,
  isMidRuns,
  isTooltipActive,
  isWicket,
  showBallReviews,
  showExtras,
} from '^/match-centre/bbb/utils.ts';
import { StoreState } from '^/match-centre/store/types';
import TooltipContent from '^/match-centre/tooltip-content';

export interface StateProps {
  selected: string | null;
  onUpdateSelectedBall: () => void;
  onResetSelectedBall: () => void;
}

export interface OwnProps {
  ball: Ball;
  onUpdate: (id: string) => void;
  onReset: () => void;
}

export type Props = OwnProps & StateProps;

export const BallComponent = ({
  ball,
  onUpdateSelectedBall,
  selected,
  onResetSelectedBall,
}: Props) => (
  <RcTooltip
    direction="up"
    tagName="div"
    isOpen={isTooltipActive(selected, ball.ball_id)}
    content={
      <TooltipContent close={onResetSelectedBall}>
        <BallTooltipContent ball={ball} />
      </TooltipContent>
    }
  >
    <Button
      className={classnames('wi-ball', {
        'mid-runs': isMidRuns(ball.runs),
        'high-runs': isHighRuns(ball.runs),
        wicket: isWicket(ball.is_out),
        active: isTooltipActive(selected, ball.ball_id),
      })}
      onClick={
        isTooltipActive(selected, ball.ball_id)
          ? onResetSelectedBall
          : onUpdateSelectedBall
      }
    >
      <div className="wi-ball-review left">{showBallReviews(ball)}</div>
      {isWicket(ball.is_out) ? (
        <div className="wi-ball-wicket">W</div>
      ) : (
        <div
          className={classnames({
            'wi-ball-dot': hasNoRuns(ball.runs),
          })}
        >
          {!hasNoRuns(ball.runs) && ball.runs}
        </div>
      )}
      <div className="wi-ball-review right">
        {ball && showExtras(ball).map(each => each && <div>{each}</div>)}
      </div>
    </Button>
  </RcTooltip>
);

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  selected: getSelectedBall(state),
  onUpdateSelectedBall: () => props.onUpdate(props.ball.ball_id),
  onResetSelectedBall: () => props.onReset(),
});

export default connect(mapStateToProps)(React.memo(BallComponent));
