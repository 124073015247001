import classnames from 'classnames';
import React from 'react';

export interface Props {
  className?: string;
}

export const MapIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="17"
    className={classnames('wi-map-marker-icon', props.className)}
    viewBox="0 0 12 17"
  >
    <g fill-rule="evenodd" transform="translate(0.5)">
      <path
        fill-rule="nonzero"
        d="M5.625,0 C2.523375,0 0,2.523375 0,5.625 C0,6.6729375 0.29028125,7.695625 0.83965625,8.582875 L5.3048125,15.7784062 C5.39034375,15.91625 5.54103125,16 5.70309375,16 C5.70434375,16 5.7055625,16 5.7068125,16 C5.87028125,15.9987188 6.02128125,15.912375 6.10525,15.772125 L10.4565937,8.506875 C10.9756563,7.638375 11.25,6.64184375 11.25,5.625 C11.25,2.523375 8.726625,0 5.625,0 Z M9.6520625,8.0255625 L5.696,14.6308125 L1.63646875,8.0889375 C1.1791875,7.3504375 0.93125,6.4984375 0.93125,5.625 C0.93125,3.0403125 3.0403125,0.93125 5.625,0.93125 C8.2096875,0.93125 10.315625,3.0403125 10.315625,5.625 C10.315625,6.47253125 10.0840312,7.30275 9.6520625,8.0255625 Z"
      />
      <path
        fill-rule="nonzero"
        d="M5.625,2.8125 C4.0741875,2.8125 2.8125,4.0741875 2.8125,5.625 C2.8125,7.16590625 4.05365625,8.4375 5.625,8.4375 C7.21571875,8.4375 8.4375,7.14896875 8.4375,5.625 C8.4375,4.0741875 7.1758125,2.8125 5.625,2.8125 Z M5.625,7.50625 C4.58571875,7.50625 3.74375,6.66146875 3.74375,5.625 C3.74375,4.591125 4.591125,3.74375 5.625,3.74375 C6.658875,3.74375 7.503125,4.591125 7.503125,5.625 C7.503125,6.64634375 6.68075,7.50625 5.625,7.50625 Z"
      />
    </g>
  </svg>
);

export default React.memo(MapIcon);
