import { Action } from '^/match-centre/common/types';
import { UPDATE_SCORECARD } from '^/match-centre/scorecard/actions';
import { Innings, Scorecard } from '^/match-centre/scorecard/types';

export const INNINGS: string = 'innings';
export const initialState: Scorecard | null = null;

const newInningsIDs = (innings: ReadonlyArray<Innings>): string[] => {
  return innings.map((inning: Innings) => inning.innings_number);
};

const filterExistingInningsList = (
  state: Scorecard,
  innings: ReadonlyArray<Innings>
): ReadonlyArray<Innings> => {
  return (
    state &&
    state.innings.filter(
      (inning: Innings) =>
        !newInningsIDs(innings).includes(inning.innings_number)
    )
  );
};

const concatAndSortInningsList = (
  existing: ReadonlyArray<Innings>,
  upcoming: ReadonlyArray<Innings>
): ReadonlyArray<Innings> =>
  [...(existing && existing), ...(upcoming && upcoming)].sort(
    (a, b) => Number(a.innings_number) - Number(b.innings_number)
  );

const generateScorecardObject = (
  state: Scorecard,
  payload: Partial<Scorecard>
): Partial<Scorecard> => {
  const result: Partial<Scorecard> = {};

  (Object.keys(payload) as Array<keyof Scorecard>).forEach(key => {
    result[key] =
      key === INNINGS
        ? concatAndSortInningsList(
            payload[key] as ReadonlyArray<Innings>,
            filterExistingInningsList(state, payload[key] as ReadonlyArray<
              Innings
            >)
          )
        : payload[key as keyof Scorecard];
  });

  return result;
};

export function scorecard(state = initialState, action: Action<any, any>) {
  switch (action.type) {
    case UPDATE_SCORECARD:
      return (
        state && {
          ...state,
          ...generateScorecardObject(state, action.payload),
        }
      );

    default:
      return state;
  }
}
