import React from 'react';

export interface Props {
  className?: string;
}

export const QuestionMarkIcon = (props: Props) => (
  <svg width="17px" height="16px" viewBox="0 0 17 16" {...props}>
    <g>
      <path
        d="M8.64,2.813c-0.834,0-1.553,0.177-2.157,0.53c-0.562,0.313-1.062,0.803-1.5,1.47
        C4.923,4.887,4.9,4.985,4.921,5.078c0.02,0.094,0.073,0.172,0.156,0.235l1.094,0.812c0.066,0.063,0.158,0.093,0.25,0.078
        c0.1-0.008,0.191-0.059,0.25-0.14C7.003,5.667,7.265,5.396,7.453,5.25c0.291-0.208,0.641-0.317,1-0.312
        c0.354,0,0.682,0.098,0.985,0.296c0.301,0.198,0.453,0.443,0.453,0.735c0,0.23-0.104,0.448-0.283,0.594
        C9.482,6.667,9.244,6.823,8.89,7.031L8.828,7.063c-0.521,0.291-0.886,0.552-1.095,0.78C7.367,8.219,7.164,8.725,7.171,9.25v0.125
        c0,0.104,0.035,0.193,0.109,0.266C7.353,9.714,7.441,9.75,7.546,9.75h1.75c0.1,0.002,0.195-0.038,0.266-0.109
        c0.07-0.07,0.111-0.166,0.109-0.266V9.344c0-0.167,0.082-0.312,0.25-0.438c0.104-0.083,0.301-0.208,0.594-0.375
        c0.541-0.333,0.926-0.625,1.156-0.875c0.395-0.438,0.594-0.99,0.594-1.656c0-0.583-0.178-1.12-0.533-1.61
        c-0.355-0.492-0.828-0.889-1.375-1.156C9.826,2.96,9.238,2.816,8.64,2.813z"
      />
      <path
        d="M8.401,10.562c-0.396,0-0.734,0.141-1.017,0.422C7.104,11.266,6.962,11.604,6.962,12s0.142,0.734,0.422,1.016
        c0.282,0.279,0.62,0.422,1.017,0.422c0.396,0,0.734-0.141,1.015-0.422C9.695,12.734,9.838,12.396,9.838,12
        s-0.141-0.734-0.422-1.016C9.15,10.709,8.783,10.557,8.401,10.562z"
      />
    </g>
  </svg>
);

export default React.memo(QuestionMarkIcon);
