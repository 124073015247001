import {
  TeamPercent,
  TeamPositionObject,
  TeamRole,
} from '^/match-centre/graphs/types';
import { Innings } from '^/match-centre/scorecard/types';

export const generateTeamPositionObject = (
  innings: Innings,
  id: string | null
): TeamPositionObject => ({
  team: id,
  inning: innings.innings_number,
  role:
    (innings.batting_team_id === id && TeamRole.BATTING_TEAM_ID) ||
    (innings.bowling_team_id === id && TeamRole.BOWLING_TEAM_ID) ||
    null,
  history:
    (innings.batting_team_id === id && TeamPercent.BATTING_TEAM_PERCENT) ||
    (innings.bowling_team_id === id && TeamPercent.BOWLING_TEAM_PERCENT) ||
    null,
});
