import {
  BattedStatus,
  BatterStatus,
  Batting,
  Bowling,
  BowlingStatus,
} from '^/match-centre/scorecard/types';
import { currentBattingSelector } from '^/match-centre/selectors';
import { currentInningsSelector } from '^/match-centre/selectors';
import { createSelector } from 'reselect';

export const onPitchBattersSelector = createSelector(
  currentBattingSelector,
  (batting): ReadonlyArray<Batting> | [] =>
    (batting &&
      batting.filter(
        batter =>
          batter.batted === BattedStatus.HAS_BATTED &&
          batter.is_out === BatterStatus.ON_PITCH
      )) ||
    []
);

export const hasOnPitchBattersSelector = createSelector(
  onPitchBattersSelector,
  (batters): boolean => batters && batters.length > 0
);

export const upcomingBattingSelector = createSelector(
  currentBattingSelector,
  onPitchBattersSelector,
  (allBatters, onPitchBatters): Batting | undefined => {
    if (allBatters && onPitchBatters) {
      const allUpcomingBatters = allBatters.filter(
        batter => batter.batted === 'DNB'
      );
      const sortedAllUpcomingBatters = allUpcomingBatters.sort(batter =>
        parseInt(batter.batting_position, 10)
      );
      return sortedAllUpcomingBatters && sortedAllUpcomingBatters[0];
    }
  }
);

export const currentBowlingSelector = createSelector(
  currentInningsSelector,
  (currentInnings): ReadonlyArray<Bowling> => {
    return currentInnings ? currentInnings.bowling : [];
  }
);

export const onPitchBowlersSelector = createSelector(
  currentBowlingSelector,
  (currentBowling): ReadonlyArray<Bowling> => {
    return currentBowling
      ? currentBowling.filter(
          item =>
            item.live_status === BowlingStatus.CURRENT ||
            item.live_status === BowlingStatus.PREVIOUS
        )
      : [];
  }
);

export const showBatterXrQualitySelector = createSelector(
  onPitchBattersSelector,
  (batters): boolean =>
    batters &&
    batters.some(
      batter => batter.hasOwnProperty('xR_diff') && batter.xR_diff !== null
    )
);

export const showBowlerQualitySelector = createSelector(
  onPitchBowlersSelector,
  (bowlers): boolean =>
    bowlers &&
    bowlers.some(
      bowler => bowler.hasOwnProperty('xw_rating') && bowler.xw_rating !== null
    )
);
