import React from 'react';
import { connect } from 'react-redux';

import {
  resetSelectedBall,
  updateSelectedBall,
} from '^/match-centre/bbb/actions';
import BallComponent from '^/match-centre/bbb/ball';
import { Ball, ModifiedBallsRecord } from '^/match-centre/bbb/types';

export interface OwnProps {
  item: ModifiedBallsRecord;
}

export interface DispatchProps {
  onUpdateSelectedBall: typeof updateSelectedBall;
  onResetSelectedBall: typeof resetSelectedBall;
}

export type Props = OwnProps & DispatchProps;

export const Over = ({
  item,
  onUpdateSelectedBall,
  onResetSelectedBall,
}: Props) => (
  <div className="wi-over">
    <div className="wi-over-title">Over {item.over}</div>

    <div className="wi-balls-list">
      {item.balls.map((ball: Ball) => (
        <BallComponent
          key={ball.ball_id}
          ball={ball}
          onUpdate={onUpdateSelectedBall}
          onReset={onResetSelectedBall}
        />
      ))}
    </div>
  </div>
);

export default connect(
  null,
  {
    onUpdateSelectedBall: updateSelectedBall,
    onResetSelectedBall: resetSelectedBall,
  }
)(React.memo(Over));
