export const UPDATE_INFO_TOOLTIP = 'UPDATE_INFO_TOOLTIP';

export const updateInfoTooltip = (id: string) => ({
  type: UPDATE_INFO_TOOLTIP,
  payload: id,
});

export const CLEAR_INFO_TOOLTIPS = 'CLEAR_INFO_TOOLTIPS';

export const clearInfoTooltips = () => ({
  type: CLEAR_INFO_TOOLTIPS,
});
