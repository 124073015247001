import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from '^/match-centre/app';
import prepopulateStore from '^/match-centre/store';
import { StoreState } from '^/match-centre/store/types';

window.renderMatchCentre = (
  element: HTMLElement,
  pusherApiKey: string,
  initialState: Partial<StoreState>
) => {
  ReactDOM.render(
    <Provider store={prepopulateStore(initialState)}>
      <App pusherApiKey={pusherApiKey} />
    </Provider>,
    element
  );
};
