import { Ball } from '^/match-centre/bbb/types';

export const UPDATE_BBB = 'UPDATE_BBB';

export const updateBbb = (data: Ball) => ({
  type: UPDATE_BBB,
  payload: data,
});

export const UPDATE_INNINGS_TAB = 'UPDATE_INNINGS_TAB';

export const updateInningsTab = (id: string) => ({
  type: UPDATE_INNINGS_TAB,
  payload: id,
});

export const RESET_INNINGS_TAB = 'RESET_INNINGS_TAB';

export const resetInningsTab = () => ({
  type: RESET_INNINGS_TAB,
});

export const UPDATE_SELECTED_BALL = 'UPDATE_SELECTED_BALL';

export const updateSelectedBall = (id: string) => ({
  type: UPDATE_SELECTED_BALL,
  payload: id,
});

export const RESET_SELECTED_BALL = 'RESET_SELECTED_BALL';

export const resetSelectedBall = () => ({
  type: RESET_SELECTED_BALL,
});
