import { createSelector, Selector } from 'reselect';

import { ballListSelector } from '^/match-centre/bbb/selectors';
import { Ball } from '^/match-centre/bbb/types';
import { StringPropertyNames } from '^/match-centre/common/types';
import { concatNestedMultipleArrays } from '^/match-centre/common/utils';
import {
  PercentByTeamPosition,
  TeamPositionObject,
} from '^/match-centre/graphs/types';
import { generateTeamPositionObject } from '^/match-centre/graphs/utils';
import { metaDataSelector } from '^/match-centre/info-button/selectors';
import { Pitchviz } from '^/match-centre/scorecard/types';
import { Innings } from '^/match-centre/scorecard/types';
import { inningsSelector, pitchvizSelector } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import {
  getTeamOneId,
  getTeamTwoId,
} from '^/match-centre/summary-panel/selectors';

export const pitchvizMetaSelector = metaDataSelector('pitchviz');

export const hasPitchvizSelector = createSelector(
  pitchvizSelector,
  (pitchviz: ReadonlyArray<Pitchviz> | null): boolean =>
    Boolean(pitchviz && pitchviz.length)
);

export const roundedPitchvizKeySelector = (
  key: StringPropertyNames<Pitchviz>
) =>
  createSelector(
    pitchvizSelector,
    hasPitchvizSelector,
    (pitchviz: ReadonlyArray<Pitchviz> | null, hasPitchviz: boolean): number =>
      pitchviz && hasPitchviz && pitchviz[0].hasOwnProperty(key)
        ? Math.round(Number(pitchviz[0][key]))
        : 0
  );

export const pitchvizOverallSelector = roundedPitchvizKeySelector(
  'pitchviz_overall'
);

export const pitchvizBounceSelector = roundedPitchvizKeySelector('bounce');

export const pitchvizBounceConsistencySelector = roundedPitchvizKeySelector(
  'bounce_consistency'
);

export const pitchvizDeviationPaceSelector = roundedPitchvizKeySelector(
  'deviation_pace'
);

export const pitchvizDeviationSpinSelector = roundedPitchvizKeySelector(
  'deviation_spin'
);

export const pitchvizSwingPaceSelector = roundedPitchvizKeySelector(
  'swing_pace'
);

export const pitchvizTrendSelector = createSelector(
  pitchvizSelector,
  hasPitchvizSelector,
  (pitchviz: ReadonlyArray<Pitchviz> | null, hasPitchviz: boolean): string =>
    pitchviz && hasPitchviz ? pitchviz[0].pitchviz_trend : ''
);

export const isPitchvizTrendingEasier = createSelector(
  pitchvizTrendSelector,
  (trend: string): boolean => trend === 'easier'
);

export const isPitchvizTrendingHarder = createSelector(
  pitchvizTrendSelector,
  (trend: string): boolean => trend === 'harder'
);

export const getInningsByTeamPositionSelector = (
  teamId: Selector<StoreState, string | null>
) =>
  createSelector(
    [teamId, inningsSelector],
    (
      id: string | null,
      innings: ReadonlyArray<Innings>
    ): ReadonlyArray<TeamPositionObject> =>
      innings &&
      innings.map((item: Innings) => generateTeamPositionObject(item, id))
  );

export const getInningsByTeamOnePositionSelector = getInningsByTeamPositionSelector(
  getTeamOneId
);

export const getInningsByTeamTwoPositionSelector = getInningsByTeamPositionSelector(
  getTeamTwoId
);

export const getPercentByTeamPosition = (
  teamPosition: Selector<StoreState, ReadonlyArray<TeamPositionObject>>
) =>
  createSelector(
    [teamPosition, ballListSelector],
    (
      positions: ReadonlyArray<TeamPositionObject>,
      balls: ReadonlyArray<Ball> | null
    ): ReadonlyArray<PercentByTeamPosition> =>
      positions &&
      [...positions]
        .sort((a, b) => Number(a.inning) - Number(b.inning))
        .map((item: TeamPositionObject) => ({
          ...item,
          balls:
            balls && balls.length
              ? [...balls]
                  .filter(
                    (ball: Ball) => item.role && ball[item.role] === item.team
                  )
                  .sort((a, b) => a.sort_key.localeCompare(b.sort_key))
              : [],
        }))
  );

export const getPercentByTeamOnePosition = getPercentByTeamPosition(
  getInningsByTeamOnePositionSelector
);

export const getPercentByTeamTwoPosition = getPercentByTeamPosition(
  getInningsByTeamTwoPositionSelector
);

export const getListOfPercentByTeamPosition = (
  data: Selector<StoreState, ReadonlyArray<PercentByTeamPosition>>
) =>
  createSelector(
    [data],
    (
      list: ReadonlyArray<PercentByTeamPosition>
    ): ReadonlyArray<ReadonlyArray<number>> =>
      list.map((item: PercentByTeamPosition) =>
        item.balls &&
        item.balls.length &&
        item.history &&
        item.balls[0].winviz.hasOwnProperty(item.history)
          ? item.balls.map((ball: Ball) =>
              Number(item.history && ball.winviz[item.history])
            )
          : []
      )
  );

export const getListOfPercentByTeamOnePosition = getListOfPercentByTeamPosition(
  getPercentByTeamOnePosition
);

export const getListOfPercentByTeamTwoPosition = getListOfPercentByTeamPosition(
  getPercentByTeamTwoPosition
);

export const listOfTeamPercentagesByTeam = (
  team: Selector<StoreState, ReadonlyArray<ReadonlyArray<number>>>
) =>
  createSelector(
    [team],
    (list: ReadonlyArray<ReadonlyArray<number>>): ReadonlyArray<number> =>
      concatNestedMultipleArrays(list)
  );

export const listOfTeamOnePercentagesSelector = listOfTeamPercentagesByTeam(
  getListOfPercentByTeamOnePosition
);

export const listOfTeamTwoPercentagesSelector = listOfTeamPercentagesByTeam(
  getListOfPercentByTeamTwoPosition
);

export const hasWinvizHistorySelector = createSelector(
  [listOfTeamOnePercentagesSelector, listOfTeamTwoPercentagesSelector],
  (teamOne: ReadonlyArray<number>, teamTwo: ReadonlyArray<number>): boolean =>
    Boolean(teamOne.length || teamTwo.length)
);
