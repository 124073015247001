import ChartJS, { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import React from 'react';

export interface Props {
  id: string;
  datasets: ChartDataSets[];
  labels?: Array<string | string[]>;
  options?: ChartOptions;
  type?: ChartType | string;
}

export class Chart extends React.PureComponent<Props> {
  private ctx?: CanvasRenderingContext2D | null;
  private chart?: ChartJS;
  private canvas?: HTMLCanvasElement | null;

  public componentDidMount() {
    const { labels, datasets, type, options, id } = this.props;

    this.canvas = document.getElementById(id) as HTMLCanvasElement | null;

    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');

      if (this.ctx) {
        this.chart = new ChartJS(this.ctx, {
          type: type || 'line',
          data: {
            labels: labels || [],
            datasets,
          },
          options: options || {},
        });
      }
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const { labels, datasets } = this.props;

    if (
      this.chart &&
      (datasets !== prevProps.datasets || labels !== prevProps.labels)
    ) {
      this.chart.data = { labels: labels || [], datasets };
      this.chart.update();
    }
  }

  public render() {
    return <canvas id={this.props.id || 'wi-canvas'} className="wi-canvas" />;
  }
}

export default Chart;
