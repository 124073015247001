import { anyPending } from '@dabapps/redux-requests';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { UPDATE_MATCHES, updateLiveMatchesData } from './actions';
import {
  DATE_FORMAT_TIME_ENTRY_DISPLAY,
  DEFAULT_INTERVAL_TIME,
} from './constants';
import { LiveMatches } from './live-matches';
import { RecentMatches } from './recent-matches';
import {
  getFormattedMatchesDate,
  hasCurrentUpstreamMatchData,
  hasLiveUpstreamMatchData,
  liveMatchesExist,
  noMatchesExist,
  recentMatchesExist,
  upcomingMatchesExist,
} from './selectors';
import {
  GroupedRecentData,
  GroupedUpcomingData,
  LiveMatch,
  StoreState,
} from './store/types';
import { UpcomingMatches } from './upcoming-matches';

export interface StateProps extends Pick<StoreState, 'allMatches'> {
  liveMatches?: LiveMatch[];
  upcomingMatches?: ReadonlyArray<GroupedUpcomingData>;
  recentMatches?: ReadonlyArray<GroupedRecentData>;
  liveUpstreamMatchData: ReadonlyArray<LiveMatch> | null;
  currentUpstreamMatchData: ReadonlyArray<LiveMatch> | null;
  anyLive: boolean;
  anyRecent: boolean;
  anyUpcoming: boolean;
  noMatches: boolean;
  loading?: boolean;
}

export interface DispatchProps {
  updateLiveMatchesData: typeof updateLiveMatchesData;
}

export type Props = StateProps & DispatchProps;

export class App extends PureComponent<Props> {
  public intervalTime = DEFAULT_INTERVAL_TIME;
  public intervalId: number | undefined;

  constructor(props: Props) {
    super(props);
    this.props.updateLiveMatchesData();
    this.intervalTime =
      (this.props.allMatches
        ? this.props.allMatches.poll_interval
        : DEFAULT_INTERVAL_TIME) * 1000;
  }

  public componentDidMount() {
    this.intervalId = window.setInterval(
      () => this.props.updateLiveMatchesData(),
      this.intervalTime
    );
  }

  public componentWillUnmount() {
    window.clearInterval(this.intervalId);
  }

  public render() {
    const {
      noMatches,
      loading,
      upcomingMatches,
      recentMatches,
      liveMatches,
    } = this.props;
    return (
      <>
        <div className="wi-match-centre">
          {liveMatches && liveMatches.length > 0 && <h3>Live Matches</h3>}
          <div className="flex-grid">
            {liveMatches &&
              liveMatches.map((item: LiveMatch, index: number) => (
                <LiveMatches
                  key={item.match_id}
                  match={item}
                  numberOfMatches={liveMatches.length}
                />
              ))}
          </div>
          {recentMatches && recentMatches.length > 0 && <h3>Recent Matches</h3>}

          {recentMatches &&
            recentMatches.map((matchIdList: GroupedRecentData) => (
              <React.Fragment key={matchIdList.match_date}>
                <div className="wi-group-subheading">
                  <div className="wi-group-subheading-seperator" />
                  <h3>
                    {getFormattedMatchesDate(
                      matchIdList.match_date,
                      DATE_FORMAT_TIME_ENTRY_DISPLAY
                    )}
                  </h3>
                  <div className="wi-group-subheading-seperator" />
                </div>

                <div className="flex-table-body">
                  {matchIdList.matches.map((match, index) => (
                    <RecentMatches key={index} match={match} />
                  ))}
                </div>
              </React.Fragment>
            ))}

          {upcomingMatches && upcomingMatches.length > 0 && (
            <h3 className="title-upcoming-matches">Upcoming Matches</h3>
          )}

          {upcomingMatches &&
            upcomingMatches.map((matchIdList: GroupedUpcomingData) => (
              <React.Fragment key={matchIdList.match_date}>
                <div className="wi-group-subheading">
                  <div className="wi-group-subheading-seperator" />
                  <h3>
                    {getFormattedMatchesDate(
                      matchIdList.match_date,
                      DATE_FORMAT_TIME_ENTRY_DISPLAY
                    )}
                  </h3>
                  <div className="wi-group-subheading-seperator" />
                </div>

                <div className="flex-table-body">
                  {matchIdList.matches.map((match, index) => (
                    <UpcomingMatches key={index} match={match} />
                  ))}
                </div>
              </React.Fragment>
            ))}
          {noMatches && !loading && (
            <>
              <h1>No matches at the moment</h1>
              <p>
                There are no live or upcoming matches at the moment. Check back
                tomorrow!
              </p>
            </>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state: StoreState): StateProps => ({
  upcomingMatches: state.allMatches
    ? state.allMatches.upcomingMatches
    : undefined,
  recentMatches: state.allMatches ? state.allMatches.recentMatches : undefined,
  liveMatches: state.allMatches ? state.allMatches.liveMatches : undefined,
  allMatches: state.allMatches,
  liveUpstreamMatchData: hasLiveUpstreamMatchData(state),
  currentUpstreamMatchData: hasCurrentUpstreamMatchData(state),
  anyLive: liveMatchesExist(state),
  anyUpcoming: upcomingMatchesExist(state),
  anyRecent: recentMatchesExist(state),
  noMatches: noMatchesExist(state),
  loading: anyPending(state.responses!, [UPDATE_MATCHES]),
});

export default connect(
  mapStateToProps,
  { updateLiveMatchesData }
)(App);
