const allAutofocusTriggers = [
  ...document.querySelectorAll('.wi-search-autofocus-trigger'),
];

if (allAutofocusTriggers.length) {
  allAutofocusTriggers.forEach(item => {
    item.addEventListener('change', () => {
      if (item.checked) {
        const autoFocus = [
          ...document.querySelectorAll('.wi-search-input-autofocus'),
        ];
        if (autoFocus.length) {
          autoFocus.forEach(input => {
            input.focus();
          });
        }
      }
    });
  });
}
