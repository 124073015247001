import {
  BattedStatus,
  BatterStatus,
  Batting,
} from '^/match-centre/scorecard/types';
import { ReactChild } from 'react';

export function getTotalAndExtraRuns(
  runs: string,
  extras: string | null
): number {
  return (parseInt(runs, 10) || 0) + (extras ? parseInt(extras, 10) : 0);
}

export function getValueIfBatted(batter: Batting, value: ReactChild) {
  return batter.batted === BattedStatus.HAS_BATTED ? value : '';
}

export const getOnPitchBatterRowClassName = (batter: Batting) => {
  const isBatting =
    batter.batted === BattedStatus.HAS_BATTED &&
    batter.is_out === BatterStatus.ON_PITCH;
  return isBatting ? 'bold' : '';
};
