import { Action } from '^/match-centre/common/types';
import {
  CLEAR_INFO_TOOLTIPS,
  UPDATE_INFO_TOOLTIP,
} from '^/match-centre/info-button/actions';

export const initialState: Set<string> = new Set();

export function infoTooltips(
  state: Set<string> = initialState,
  action: Action<any, any>
): Set<string> {
  switch (action.type) {
    case UPDATE_INFO_TOOLTIP:
      if (state.has(action.payload)) {
        const newState = new Set(state);
        newState.delete(action.payload);
        return newState;
      } else {
        const newState = new Set(state);
        newState.add(action.payload);
        return newState;
      }

    case CLEAR_INFO_TOOLTIPS:
      return new Set();

    default:
      return state;
  }
}
