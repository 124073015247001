import React from 'react';
import { connect } from 'react-redux';

import {
  getBatterBoundaryPercentage,
  getBatterDotBallPercentage,
  getBatterStrikeRate,
  getBowlerEconomy,
} from '^/match-centre/common/utils';
import { FlexTable } from '^/match-centre/flex-table';
import InfoButton from '^/match-centre/info-button';
import { updateInfoTooltip } from '^/match-centre/info-button/actions';
import {
  boundaryPercentMetaSelector,
  dotBallPercentMetaSelector,
  expectedRunsMetaSelector,
  expectedWicketsMetaSelector,
} from '^/match-centre/info-button/selectors';
import { restProductMeta } from '^/match-centre/info-button/utils';
import InningsNav from '^/match-centre/innings-nav';
import NoDataMessage from '^/match-centre/no-data-message';
import BatterDetails from '^/match-centre/scorecard/batter-details';
import BatterTotalInfo from '^/match-centre/scorecard/batter-total-info';
import BowlerDetails from '^/match-centre/scorecard/bowler-details';
import {
  constructedFowSelector,
  hasFowDataSelector,
} from '^/match-centre/scorecard/selectors';
import {
  Batting,
  Bowling,
  ConstructedFow,
  InningsTeamInfo,
} from '^/match-centre/scorecard/types';
import {
  getOnPitchBatterRowClassName,
  getTotalAndExtraRuns,
  getValueIfBatted,
} from '^/match-centre/scorecard/utils';
import {
  extraRunsSelector,
  hasInnings,
  inningsBattingTeamSelector,
  inningsBowlingTeamSelector,
  playerNameAndBattingHand,
  selectedBattingSelector,
  selectedBowlingSelector,
  totalRunsSelector,
  totalXrDiffSelector,
} from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import { ProductMeta } from '^/match-centre/summary-panel/types';
import {
  showBatterXrQualitySelector,
  showBowlerQualitySelector,
} from '^/match-centre/tables-panel/selectors';

const TOOLTIP_SCORECARD_BATTER: string = 'wi-tp-scorecard-batter';
const TOOLTIP_SCORECARD_BOWLER: string = 'wi-tp-scorecard-bowler';

export interface StateProps {
  hasAnyInnings: boolean | null;
  battingTeam: InningsTeamInfo | undefined;
  bowlingTeam: InningsTeamInfo | undefined;
  batters: ReadonlyArray<Batting>;
  extraRuns: string | null;
  bowlers: ReadonlyArray<Bowling>;
  totalRuns: string;
  totalXrDiff: string;
  showBowlerQuality: boolean;
  showBatterXr: boolean;
  battersMeta: ReadonlyArray<ProductMeta>;
  bowlersMeta: ReadonlyArray<ProductMeta>;
  fow: ReadonlyArray<ConstructedFow>;
  hasFowData: boolean;
  getPlayerNameAndBattingHandFromId: (id: string) => string;
}

export interface DispatchProps {
  toggleTooltip: typeof updateInfoTooltip;
}

export type Props = StateProps & DispatchProps;

const ScoreCard = ({
  hasAnyInnings,
  battingTeam,
  bowlingTeam,
  batters,
  extraRuns,
  bowlers,
  totalRuns,
  totalXrDiff,
  showBowlerQuality,
  showBatterXr,
  toggleTooltip,
  battersMeta,
  bowlersMeta,
  hasFowData,
  fow,
}: Props) => {
  const hasBattingInfo = batters && batters.length;
  const hasBowlingInfo = bowlers && bowlers.length;
  const displayScorecard = hasBattingInfo && hasBowlingInfo;
  return (
    <div className="wi-match-tabs-content wi-scorecard-tab">
      {hasAnyInnings && <InningsNav isScorecard />}

      {displayScorecard ? (
        <>
          <div className="wi-scorecard-tab-team">
            <div className="wi-scorecard-tab-team-image">
              {battingTeam && (
                <img src={battingTeam.logo_url || ''} alt="batting badge" />
              )}
            </div>

            <div className="wi-scorecard-tab-team-name">
              {battingTeam && battingTeam.name} Batting
            </div>
          </div>

          <FlexTable
            className="wi-scorecard-table"
            columns={[
              {
                header: (
                  <div className="wi-flex-table-header-info">
                    Batters{' '}
                    {battersMeta.length ? (
                      <InfoButton
                        tooltipClassName="info-flex-table-header"
                        tooltipId={TOOLTIP_SCORECARD_BATTER}
                        onToggle={toggleTooltip}
                        meta={battersMeta}
                      />
                    ) : null}
                  </div>
                ),
                value: batter => <BatterDetails batter={batter} />,
                total: <BatterTotalInfo />,
              },
              {
                header: 'R',
                value: batter => getValueIfBatted(batter, batter.runs),
                total: getTotalAndExtraRuns(totalRuns, extraRuns),
              },
              {
                header: 'B',
                value: batter => getValueIfBatted(batter, batter.balls_faced),
              },
              {
                header: '4s',
                value: batter => getValueIfBatted(batter, batter.fours),
              },
              {
                header: '6s',
                value: batter => getValueIfBatted(batter, batter.sixes),
              },
              {
                header: 'SR',
                value: batter =>
                  getValueIfBatted(batter, getBatterStrikeRate(batter)),
              },
              ...(showBatterXr
                ? [
                    {
                      header: 'xR',
                      value: (batter: Batting) =>
                        getValueIfBatted(batter, batter.xR_diff || 'N/A'),
                      total: totalXrDiff,
                    },
                  ]
                : []),
              {
                header: 'B%',
                value: batter =>
                  getValueIfBatted(batter, getBatterBoundaryPercentage(batter)),
              },
              {
                header: 'DB%',
                value: batter =>
                  getValueIfBatted(batter, getBatterDotBallPercentage(batter)),
              },
            ]}
            rows={batters}
            rowClassName={getOnPitchBatterRowClassName}
          />

          {hasFowData && (
            <FlexTable
              className="wi-scorecard-table wi-partnership-table"
              columns={[
                {
                  header: 'Partnership',
                  value: () => '',
                },
                {
                  header: (
                    <>
                      <span className="partnership-title">Partnership </span>
                      Wicket
                    </>
                  ),
                  value: player => player.fow_wickets,
                },
                {
                  header: 'Score (overs)',
                  value: player =>
                    `${player.fow_runs}/${player.fow_wickets} (${
                      player.fow_overs
                    })`,
                },
                {
                  header: 'Dismissed player',
                  value: player => player.dismissed,
                },
                {
                  header: 'Partner',
                  value: player => player.partner,
                },
                {
                  header: 'Runs',
                  value: player => player.partnership_runs,
                },
              ]}
              rows={fow}
            />
          )}

          <div className="wi-scorecard-tab-team">
            <div className="wi-scorecard-tab-team-image">
              {bowlingTeam && (
                <img src={bowlingTeam.logo_url || ''} alt="bowling badge" />
              )}
            </div>

            <div className="wi-scorecard-tab-team-name">
              {bowlingTeam && bowlingTeam.name} Bowling
            </div>
          </div>

          <FlexTable
            className="wi-scorecard-table"
            columns={[
              {
                header: (
                  <div className="wi-flex-table-header-info">
                    Bowler{' '}
                    {showBowlerQuality && (
                      <InfoButton
                        tooltipClassName="info-flex-table-header"
                        tooltipId={TOOLTIP_SCORECARD_BOWLER}
                        onToggle={toggleTooltip}
                        meta={bowlersMeta}
                      />
                    )}
                  </div>
                ),
                value: bowler => <BowlerDetails playerId={bowler.player_id} />,
              },
              {
                header: 'O',
                value: bowler => bowler.overs,
              },
              {
                header: 'M',
                value: bowler => bowler.maidens,
              },
              {
                header: 'R',
                value: bowler => bowler.conceded,
              },
              {
                header: 'W',
                value: bowler => bowler.wickets,
              },
              {
                header: 'E',
                value: bowler => getBowlerEconomy(bowler),
              },
              ...(showBowlerQuality
                ? [
                    {
                      header: 'Q',
                      value: (bowler: any) => bowler.xw_rating || 'N/A',
                    },
                  ]
                : []),
            ]}
            rows={bowlers}
          />
        </>
      ) : (
        <NoDataMessage text="There is no scorecard data available" />
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  hasAnyInnings: hasInnings(state),
  battingTeam: inningsBattingTeamSelector(state),
  bowlingTeam: inningsBowlingTeamSelector(state),
  batters: selectedBattingSelector(state),
  extraRuns: extraRunsSelector(state),
  bowlers: selectedBowlingSelector(state),
  totalRuns: totalRunsSelector(state),
  totalXrDiff: totalXrDiffSelector(state),
  getPlayerNameAndBattingHandFromId: (id: string) =>
    playerNameAndBattingHand(state, id),
  showBatterXr: showBatterXrQualitySelector(state),
  showBowlerQuality: showBowlerQualitySelector(state),
  battersMeta: restProductMeta(
    showBatterXrQualitySelector(state) ? expectedRunsMetaSelector(state) : null,
    boundaryPercentMetaSelector(state),
    dotBallPercentMetaSelector(state)
  ),
  bowlersMeta: restProductMeta(expectedWicketsMetaSelector(state)),
  hasFowData: hasFowDataSelector(state),
  fow: constructedFowSelector(state),
});

export { ScoreCard };

export default connect(
  mapStateToProps,
  {
    toggleTooltip: updateInfoTooltip,
  }
)(React.memo(ScoreCard));
