import React from 'react';

export interface Props {
  className?: string;
}

export const NextIcon = (props: Props) => (
  <svg
    width="18"
    height="32"
    viewBox="0 0 18 32"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="rotate(-90 9.000000953674318,15.999999046325684)"
      d="m22.1662,7.713524l-13.16461,13.167788l-13.16461,-13.169377c-0.627643,-0.627643 -1.642994,-0.627643 -2.270637,0c-0.626054,0.627643 -0.626054,1.644583 0,2.272226l14.299135,14.303901l0,0l0,0c0.626054,0.627643 1.642994,0.627643 2.269048,0l14.299135,-14.303901c0.626054,-0.627643 0.626054,-1.646172 0,-2.273815c-0.624465,-0.624465 -1.641405,-0.624465 -2.267459,0.003178z"
    />
  </svg>
);

export default React.memo(NextIcon);
