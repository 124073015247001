import { Ball } from '^/match-centre/bbb/types';

export enum TeamRole {
  BATTING_TEAM_ID = 'batting_team_id',
  BOWLING_TEAM_ID = 'bowling_team_id',
}

export enum TeamPercent {
  BATTING_TEAM_PERCENT = 'batting_team_percent',
  BOWLING_TEAM_PERCENT = 'bowling_team_percent',
}

export interface TeamPositionObject {
  history: TeamPercent | null;
  inning: string;
  role: TeamRole | null;
  team: string | null;
}

export interface PercentByTeamPosition extends TeamPositionObject {
  balls: ReadonlyArray<Ball> | null;
}
