import { createSelector } from 'reselect';

import { Ball, ModifiedBallsRecord } from '^/match-centre/bbb/types';
import { initialActiveInningsSelector } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';

export const ballListSelector = (
  state: StoreState
): ReadonlyArray<Ball> | null => state.bbb && state.bbb.ball;

export const selectedInningsTabSelector = (state: StoreState) =>
  state.activeInningsTab;

export const getSelectedBall = (state: StoreState) => state.selectedBall;

export const listOfBallsByInningsNumber = createSelector(
  ballListSelector,
  initialActiveInningsSelector,
  selectedInningsTabSelector,
  (
    balls: ReadonlyArray<Ball> | null,
    initialId: string | null,
    activeId: string | null
  ): ReadonlyArray<Ball> | null =>
    balls &&
    balls.filter(item => item.innings_number === (activeId || initialId))
);

export const listOfUniqueOverNumbers = createSelector(
  listOfBallsByInningsNumber,
  (listOfNumbers): string[] | null =>
    listOfNumbers &&
    [...new Set(listOfNumbers.map(item => item.over_number))].sort(
      (a, b) => Number(b) - Number(a)
    )
);

export const listOfUniqueBallsByInningsNumber = createSelector(
  ballListSelector,
  listOfUniqueOverNumbers,
  initialActiveInningsSelector,
  selectedInningsTabSelector,
  (
    balls,
    overs,
    initialId,
    activeId
  ): ReadonlyArray<ModifiedBallsRecord> | null =>
    overs &&
    balls && [
      ...overs.map(index => ({
        over: index,
        balls: [
          ...balls
            .filter(item => item.innings_number === (activeId || initialId))
            .filter(item => index === item.over_number)
            .sort((a, b): any => b.sort_key.localeCompare(a.sort_key)),
        ],
      })),
    ]
);
