import React from 'react';
import { connect } from 'react-redux';

import ColourCode from '^/match-centre/bbb/colour-code';
import Over from '^/match-centre/bbb/over';
import { listOfUniqueBallsByInningsNumber } from '^/match-centre/bbb/selectors';
import { ModifiedBallsRecord } from '^/match-centre/bbb/types';
import InningsNav from '^/match-centre/innings-nav';
import NoDataMessage from '^/match-centre/no-data-message';
import ScrollableMobileContainer from '^/match-centre/scrollable-mobile-container';
import { hasInnings } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';

export interface StateProps extends Partial<StoreState> {
  overs: ReadonlyArray<ModifiedBallsRecord> | null;
  hasAnyInnings: boolean | null;
}

export type Props = StateProps;

const BallByBall = ({ overs, hasAnyInnings }: Props) => (
  <div className="wi-match-tabs-content wi-ball-by-ball">
    {hasAnyInnings && <InningsNav />}

    {overs && overs.length ? (
      <ScrollableMobileContainer>
        {overs.map((item: ModifiedBallsRecord) => (
          <Over key={item.over} item={item} />
        ))}
      </ScrollableMobileContainer>
    ) : (
      <NoDataMessage text="No ball-by-ball data available" />
    )}

    <ColourCode />
  </div>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  overs: listOfUniqueBallsByInningsNumber(state),
  hasAnyInnings: hasInnings(state),
});

export { BallByBall };

export default connect(mapStateToProps)(React.memo(BallByBall));
