import { Batting, Bowling } from '^/match-centre/scorecard/types';
import ColorDiff from 'color-diff';

export const DEFAULT_GREY: string = '#E0E1E7';

export const DEFAULT_BLUE: string = '#00C0F2';
export const BLUE_DARK: string = '#0071BB';
export const DEFAULT_YELLOW: string = '#FBED00';
export const DEFAULT_PRIMARY: string = '#941A42';
export const DEFAULT_GREEN: string = '#5EA14C';

export const GREEN_DARKER: string = '#6fb52f';
export const GREEN_DARK: string = '#8cb52f';
export const GREEN_DARKEST: string = '#a0b52f';
export const GREEN_YELLOW: string = '#c6c934';
export const YELLOW: string = '#e5b31d';
export const ORANGE: string = '#f47535';
export const ORANGE_DARK: string = '#ed5c28';
export const ORANGE_DARKER: string = '#dd3a25';
export const RED: string = '#c10303';

export const PITCHVIZ_OVERALL_COLOUR_CODE: ReadonlyArray<string> = [
  DEFAULT_GREY,
  DEFAULT_GREEN,
  GREEN_DARKER,
  GREEN_DARK,
  GREEN_DARKEST,
  GREEN_YELLOW,
  YELLOW,
  ORANGE,
  ORANGE_DARK,
  ORANGE_DARKER,
  RED,
];

export const CUT_OUT_PERSENTAGE: number = 74;
export const PITCHVIZ_TOTAL: number = 10;

function truncateToDP(value: number, dp: number) {
  const multiplier = Math.pow(10, dp);
  return Math.floor(value * multiplier) / multiplier;
}

export const ORDINAL_INDICATOR: Record<string, string> = {
  '1': 'st',
  '2': 'nd',
  '3': 'rd',
  '4': 'th',
};

export function getBatterStrikeRate(batter: Batting): string {
  const balls = parseInt(batter.balls_faced, 10);
  if (balls) {
    const runs = parseInt(batter.runs, 10);
    const strikeRate = (100 * runs) / balls;
    return truncateToDP(strikeRate, 1).toString();
  } else {
    return '0';
  }
}

export function getBatterBoundaryPercentage(batter: Batting): string {
  const runs = parseInt(batter.runs, 10);
  if (runs) {
    const fours = parseInt(batter.fours, 10);
    const sixes = parseInt(batter.sixes, 10);
    const runsFromFours = fours * 4;
    const runsFromSixes = sixes * 6;
    const boundaryPercentage = ((runsFromFours + runsFromSixes) / runs) * 100;
    return truncateToDP(boundaryPercentage, 2).toString();
  } else {
    return '0';
  }
}

export function getBatterDotBallPercentage(batter: Batting): string {
  const dotFaced = parseInt(batter.dots_faced, 10);
  const ballsFaced = parseInt(batter.balls_faced, 10);
  if (dotFaced && ballsFaced) {
    const dotBallPercentage = (dotFaced / ballsFaced) * 100;
    return truncateToDP(dotBallPercentage, 2).toString();
  } else {
    return '0';
  }
}

export function getBowlerEconomy(bowler: Bowling): string {
  const overs = parseFloat(bowler.overs) || 0;
  if (overs) {
    const conceded = parseInt(bowler.conceded, 10);
    const economy = conceded / overs;
    return truncateToDP(economy, 2).toString();
  } else {
    return '0';
  }
}

export function hexToRGB(hex: string) {
  const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    throw new Error('Invalid RGB code: ' + hex);
  }
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
}

export function hexToLabColor(hex: string): ColorDiff.LabColor | null {
  if (!hex) {
    return null;
  }
  const [R, G, B] = hexToRGB(hex);
  return ColorDiff.rgb_to_lab({ R, G, B });
}

export const hexOrDefault = (colour: string, defaultColour: string): string =>
  Boolean(colour) ? `#${colour}` : defaultColour;

export const hexToRGBA = (colour: string, opacity: number): string => {
  const [R, G, B] = hexToRGB(colour.replace('#', ''));
  return `rgba(${R},${G},${B},${opacity})`;
};

export const concatNestedMultipleArrays = (
  list: ReadonlyArray<ReadonlyArray<number>>
): ReadonlyArray<number> => list.reduce((memo, item) => [...memo, ...item], []);
