import classnames from 'classnames';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import { updateInningsTab } from '^/match-centre/bbb/actions';
import InfoButton from '^/match-centre/info-button';
import { updateInfoTooltip } from '^/match-centre/info-button/actions';
import { windiesBbbMetaSelector } from '^/match-centre/info-button/selectors';
import { restProductMeta } from '^/match-centre/info-button/utils';
import InningsNavItem from '^/match-centre/innings-nav/innings-nav-item';
import { Innings } from '^/match-centre/scorecard/types';
import {
  addActualInningsSelector,
  initialActiveInningsSelector,
} from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import { ProductMeta } from '^/match-centre/summary-panel/types';

export interface OwnProps {
  isScorecard?: boolean;
}

export interface StateProps extends Pick<StoreState, 'activeInningsTab'> {
  innings: ReadonlyArray<Innings> | null;
  meta: ReadonlyArray<ProductMeta>;
}

export interface DispatchProps {
  onClickInningsTab: typeof updateInningsTab;
  toggleTooltip: typeof updateInfoTooltip;
}

export type Props = OwnProps & StateProps & DispatchProps;

const TOOLTIP_ID: string = 'wi-bbb-info';

export const InningsNav: FunctionComponent<Props> = ({
  isScorecard,
  activeInningsTab,
  innings,
  onClickInningsTab,
  toggleTooltip,
  meta,
}) => (
  <div
    className={classnames('wi-inning-nav', {
      'wi-scorecard-innings-nav': isScorecard,
    })}
  >
    {innings &&
      innings.map((item: Innings) => (
        <InningsNavItem
          key={item.innings_number}
          data={item}
          onClick={onClickInningsTab}
          activeInnings={activeInningsTab}
          isScorecard={isScorecard}
        />
      ))}

    {!isScorecard && (
      <InfoButton
        direction="left"
        tooltipId={TOOLTIP_ID}
        onToggle={toggleTooltip}
        meta={meta}
      />
    )}
  </div>
);

export const mapStateToProps = (state: StoreState): StateProps => {
  return {
    innings: addActualInningsSelector(state),
    activeInningsTab:
      state.activeInningsTab || initialActiveInningsSelector(state),
    meta: restProductMeta(windiesBbbMetaSelector(state)),
  };
};

export default connect<StateProps, DispatchProps, OwnProps, StoreState>(
  mapStateToProps,
  {
    onClickInningsTab: updateInningsTab,
    toggleTooltip: updateInfoTooltip,
  }
)(React.memo(InningsNav));
