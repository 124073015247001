import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';

import {
  getBatterBoundaryPercentage,
  getBatterDotBallPercentage,
  getBatterStrikeRate,
  getBowlerEconomy,
} from '^/match-centre/common/utils';
import { FlexTable } from '^/match-centre/flex-table';
import InfoButton from '^/match-centre/info-button';
import { updateInfoTooltip } from '^/match-centre/info-button/actions';
import {
  boundaryPercentMetaSelector,
  dotBallPercentMetaSelector,
  expectedRunsMetaSelector,
  expectedWicketsMetaSelector,
} from '^/match-centre/info-button/selectors';
import { restProductMeta } from '^/match-centre/info-button/utils';
import BowlerDetails from '^/match-centre/scorecard/bowler-details';
import {
  Batter,
  Batting,
  Bowling,
  BowlingStatus,
} from '^/match-centre/scorecard/types';
import { playerNameAndBattingHand } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import { ProductMeta } from '^/match-centre/summary-panel/types';
import {
  onPitchBattersSelector,
  onPitchBowlersSelector,
  showBatterXrQualitySelector,
  showBowlerQualitySelector,
  upcomingBattingSelector,
} from '^/match-centre/tables-panel/selectors';

const TOOLTIP_BATTER_SUMMARY_ID: string = 'wi-tp-summary-batter';
const TOOLTIP_BOWLER_SUMMARY_ID: string = 'wi-tp-summary-bowler';

const ActivePlayerIcon = () => <div className="wi-striker" />;

export interface StateProps {
  onPitchBatters: ReadonlyArray<Batting> | [];
  onPitchBowlers: ReadonlyArray<Bowling>;
  nextBatter: Batting | undefined;
  battersMeta: ReadonlyArray<ProductMeta>;
  bowlersMeta: ReadonlyArray<ProductMeta>;
  showBowlerQuality: boolean;
  showBatterXr: boolean;
  getPlayerNameAndBattingHand: (id: string) => string;
}

export interface DispatchProps {
  toggleTooltip: typeof updateInfoTooltip;
}

export type Props = StateProps & DispatchProps;

export const TablesPanel: FunctionComponent<Props> = ({
  getPlayerNameAndBattingHand,
  nextBatter,
  onPitchBatters,
  onPitchBowlers,
  battersMeta,
  bowlersMeta,
  toggleTooltip,
  showBowlerQuality,
  showBatterXr,
}) => {
  return (
    <div className="wi-batters-content">
      <FlexTable
        className="wi-batters-table"
        columns={[
          {
            header: '',
            value: (batter: Batting): React.ReactElement | string =>
              batter.live_status === Batter.STRIKER ? <ActivePlayerIcon /> : '',
          },
          {
            header: (
              <div className="wi-flex-table-header-info">
                Batters{' '}
                {battersMeta.length ? (
                  <InfoButton
                    tooltipClassName="info-flex-table-header"
                    tooltipId={TOOLTIP_BATTER_SUMMARY_ID}
                    onToggle={toggleTooltip}
                    meta={battersMeta}
                  />
                ) : null}
              </div>
            ),
            value: batter => getPlayerNameAndBattingHand(batter.player_id),
          },
          {
            header: 'R',
            value: batter => batter.runs,
          },
          {
            header: 'B',
            value: batter => batter.balls_faced,
          },
          {
            header: '4s',
            value: batter => batter.fours,
          },
          {
            header: '6s',
            value: batter => batter.sixes,
          },
          {
            header: 'SR',
            value: batter => getBatterStrikeRate(batter),
          },
          ...(showBatterXr
            ? [
                {
                  header: 'xR',
                  value: (batter: Batting) => batter.xR_diff || '0',
                },
              ]
            : []),
          {
            header: 'B%',
            value: batter => getBatterBoundaryPercentage(batter),
          },
          {
            header: 'DB%',
            value: batter => getBatterDotBallPercentage(batter),
          },
        ]}
        rows={onPitchBatters}
      />

      {nextBatter && (
        <div className="wi-upcoming-batter">
          <div>{getPlayerNameAndBattingHand(nextBatter.player_id)}</div>
          <div>{`Next in Bat (No.${nextBatter.batting_position})`}</div>
        </div>
      )}

      {onPitchBowlers.length > 0 && (
        <FlexTable
          className="wi-batters-table"
          columns={[
            {
              header: '',
              value: (bowler: Bowling): React.ReactElement | string =>
                bowler.live_status === BowlingStatus.CURRENT ? (
                  <ActivePlayerIcon />
                ) : (
                  ''
                ),
            },
            {
              header: (
                <div className="wi-flex-table-header-info">
                  Bowler{' '}
                  {showBowlerQuality && (
                    <InfoButton
                      tooltipClassName="info-flex-table-header"
                      tooltipId={TOOLTIP_BOWLER_SUMMARY_ID}
                      onToggle={toggleTooltip}
                      meta={bowlersMeta}
                    />
                  )}
                </div>
              ),
              value: bowler => <BowlerDetails playerId={bowler.player_id} />,
            },
            {
              header: 'O',
              value: bowler => bowler.overs,
            },
            {
              header: 'M',
              value: bowler => bowler.maidens,
            },
            {
              header: 'R',
              value: bowler => bowler.conceded,
            },
            {
              header: 'W',
              value: bowler => bowler.wickets,
            },
            {
              header: 'E',
              value: bowler => getBowlerEconomy(bowler),
            },
            ...(showBowlerQuality
              ? [
                  {
                    header: 'Q',
                    value: (bowler: any) => bowler.xw_rating || 'N/A',
                  },
                ]
              : []),
          ]}
          rows={onPitchBowlers}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  onPitchBatters: onPitchBattersSelector(state),
  onPitchBowlers: onPitchBowlersSelector(state),
  nextBatter: upcomingBattingSelector(state),
  getPlayerNameAndBattingHand: (id: string) =>
    playerNameAndBattingHand(state, id),
  showBowlerQuality: showBowlerQualitySelector(state),
  showBatterXr: showBatterXrQualitySelector(state),
  battersMeta: restProductMeta(
    showBatterXrQualitySelector(state) ? expectedRunsMetaSelector(state) : null,
    boundaryPercentMetaSelector(state),
    dotBallPercentMetaSelector(state)
  ),
  bowlersMeta: restProductMeta(expectedWicketsMetaSelector(state)),
});

export default connect(
  mapStateToProps,
  {
    toggleTooltip: updateInfoTooltip,
  }
)(React.memo(TablesPanel));
