import { UPDATE_MATCHES } from '^/match-centre-dashboard/actions';
import {
  LiveMatch,
  MatchCentreState,
} from '^/match-centre-dashboard/store/types';
import { AnyAction } from 'redux';
import {
  getGroupedMatchesList,
  getGroupedRecentMatchesList,
} from './selectors';

const updateLiveMacthes = (update: LiveMatch[], liveMatches: LiveMatch[]) => {
  if (!liveMatches.length) {
    return update;
  }
  update.forEach(m => {
    const index = liveMatches.findIndex(u => u.match_id === m.match_id);
    if (index === -1) {
      liveMatches.push(m);
    } else {
      liveMatches.splice(index, 1, m);
    }
  });
  return liveMatches;
};

const allMatches = (
  state: MatchCentreState | null = null,
  action: AnyAction
): MatchCentreState | null => {
  switch (action.type) {
    case UPDATE_MATCHES.SUCCESS:
      return {
        ...action.payload.data,
        liveMatches: updateLiveMacthes(
          action.payload.data.upstream_data.match.live,
          state && state.liveMatches ? state.liveMatches : []
        ),
        upcomingMatches:
          state && state.upcomingMatches
            ? state.upcomingMatches
            : getGroupedMatchesList(
                action.payload.data.upstream_data.match.forthcoming
              ),
        recentMatches:
          state && state.recentMatches
            ? state.recentMatches
            : getGroupedRecentMatchesList(
                action.payload.data.upstream_data.match.complete
              ),
      };
    case UPDATE_MATCHES.FAILURE:
      return null;
    default:
      return state;
  }
};

export { allMatches };
