import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { resetInningsTab, resetSelectedBall } from '^/match-centre/bbb/actions';
import { clearInfoTooltips } from '^/match-centre/info-button/actions';
import { TabItem } from '^/match-centre/tabs-panel/types';

export interface DispatchProps {
  onTabClick?: () => void;
}

export interface OwnProps {
  tab: TabItem;
  selectedTabPath: string | null;
}

export type Props = OwnProps & DispatchProps;

const isActive = (a: string | null, b: string) => a === b;

const Tab = ({ tab, onTabClick, selectedTabPath }: Props) => (
  <a
    className={classnames('wi-match-tab', {
      active: isActive(selectedTabPath, tab.path),
    })}
    onClick={onTabClick}
    href={!isActive(selectedTabPath, tab.path) ? `#${tab.path}` : undefined}
  >
    {tab.name}
  </a>
);

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  props: Props
): DispatchProps => ({
  onTabClick: () => {
    dispatch(resetInningsTab());
    dispatch(resetSelectedBall());
    dispatch(clearInfoTooltips());
  },
});

export { Tab };

export default connect(
  null,
  mapDispatchToProps
)(React.memo(Tab));
