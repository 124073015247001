import { Button } from '@dabapps/roe';
import React, { ReactElement } from 'react';

import CloseIcon from '^/match-centre/common/close-icon';

export interface Props {
  children: ReactElement;
  close?: () => void;
}

export const TooltipContent = ({ close, children }: Props) => (
  <div className="wi-tooltip-content">
    <Button className="wi-tooltip-content-button" onClick={close}>
      <CloseIcon className="wi-tooltip-content-svg" />
    </Button>
    {children}
  </div>
);

export default React.memo(TooltipContent);
