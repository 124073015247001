import { Button } from '@dabapps/roe';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { resetSelectedBall } from '^/match-centre/bbb/actions';
import { ORDINAL_INDICATOR } from '^/match-centre/common/utils';
import { clearInfoTooltips } from '^/match-centre/info-button/actions';
import { Class, Innings, Team } from '^/match-centre/scorecard/types';
import { StoreState } from '^/match-centre/store/types';
import { getTeamFromId } from '^/match-centre/summary-panel/selectors';

export interface StateProps {
  team: Team | undefined;
  isOneDayMatch?: boolean | null;
}

export interface DispatchProps {
  onUpdateInningsTab?: () => void;
}

export interface OwnProps {
  data: Innings;
  activeInnings: string | null;
  isScorecard?: boolean;
  onClick: (id: string) => void;
}

export type Props = OwnProps & DispatchProps & StateProps;

const FIRST: string = '1';

const isActive = (a: string | null, b: string) => a === b;

const isOneDayMatchCheck = (types: ReadonlyArray<Class>) => {
  return !!types.find(
    t =>
      t.id === '2' ||
      t.id === '3' ||
      t.id === '5' ||
      t.id === '6' ||
      t.id === '12' ||
      t.id === '13' ||
      t.id === '15' ||
      t.id === '16' ||
      t.id === '22' ||
      t.id === '23' ||
      t.id === '32' ||
      t.id === '33' ||
      t.id === '36' ||
      t.id === '41' ||
      t.id === '43' ||
      t.id === '44'
  );
};

export const InningsNavItem = ({
  data,
  activeInnings,
  onUpdateInningsTab,
  isScorecard,
  team,
  isOneDayMatch,
}: Props) => (
  <>
    <Button
      className={classnames('wi-innings-nav-item', {
        active: isActive(activeInnings, data.innings_number),
      })}
      key={data.innings_number}
      onClick={onUpdateInningsTab}
      disabled={isActive(activeInnings, data.innings_number)}
    >
      <span>
        {isScorecard
          ? `${team ? team.abbreviation : 'N/A'} ${
              isOneDayMatch ? '' : data.actual_innings_number
            }${
              isOneDayMatch ? '' : ORDINAL_INDICATOR[data.actual_innings_number]
            } Inns`
          : `${isOneDayMatch ? '' : data.actual_innings_number}${
              isOneDayMatch ? '' : ORDINAL_INDICATOR[data.actual_innings_number]
            } Innings`}
      </span>
    </Button>

    {!isScorecard && data.innings_number !== FIRST && (
      <div className="wi-innings-nav-item-seperator">|</div>
    )}
  </>
);

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  team: getTeamFromId(state, props.data.batting_team_id),
  isOneDayMatch:
    state.scorecard && isOneDayMatchCheck(state.scorecard.match.class),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
  props: OwnProps
): DispatchProps => ({
  onUpdateInningsTab: () => {
    props.onClick(props.data.innings_number);
    dispatch(resetSelectedBall());
    dispatch(clearInfoTooltips());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(InningsNavItem));
