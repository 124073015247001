import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';

import { Article } from '^/match-centre/articles/types';
import ClockIcon from '^/match-centre/common/clock-icon';
import NoDataMessage from '^/match-centre/no-data-message';
import { StoreState } from '^/match-centre/store/types';

interface StoreProps {
  articles: ReadonlyArray<Article>;
}

export type Props = StoreProps;

const Articles = ({ articles }: Props) => {
  return (
    <div
      className={classnames('wi-match-tabs-content wi-articles', {
        'has-articles': Boolean(articles.length),
      })}
    >
      {Boolean(articles.length) ? (
        articles.map(article => (
          <div key={article.id} className="column xs-24 xl-12">
            <div className="wi-article">
              {article.thumbnail && (
                <a
                  className="wi-article-image-link"
                  href={article.url}
                  target="_blank"
                >
                  <img
                    className="wi-article-thumbnail"
                    src={article.thumbnail}
                  />
                </a>
              )}

              <div className="wi-article-details">
                <a
                  className="wi-article-title"
                  href={article.url}
                  target="_blank"
                >
                  {article.title}
                </a>

                <span className="wi-article-date">
                  <ClockIcon />{' '}
                  {moment(article.latest_revision_created_at).fromNow()}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <NoDataMessage text="There are no articles available for this match." />
      )}
    </div>
  );
};

export const mapStateToProps = (state: StoreState) => ({
  articles: state.articles,
});

export { Articles };

export default connect(mapStateToProps)(React.memo(Articles));
