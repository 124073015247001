import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from '^/match-centre-dashboard/app';
import prepopulateStore from '^/match-centre-dashboard/store';
import { StoreState } from '^/match-centre-dashboard/store/types';

window.renderMatchCentreDashboard = (
  element: HTMLElement,
  initialState: Partial<StoreState>
) => {
  ReactDOM.render(
    <Provider store={prepopulateStore(initialState)}>
      <App />
    </Provider>,
    element
  );
};
