import { Scorecard } from '^/match-centre/scorecard/types';

export const UPDATE_SCORECARD = 'UPDATE_SCORECARD';

export const updateScorecard = (data: Partial<Scorecard>) => ({
  type: UPDATE_SCORECARD,
  payload: data,
});

export const SUBSCRIBE_PUSHER_CHANNELS = 'SUBSCRIBE_PUSHER_CHANNELS';

export const subscribePusherChannels = (key: string) => ({
  type: SUBSCRIBE_PUSHER_CHANNELS,
  payload: key,
});

export const UNSUBSCRIBE_PUSHER_CHANNELS = 'UNSUBSCRIBE_PUSHER_CHANNELS';

export const unSubscribePusherChannels = (key: string) => ({
  type: UNSUBSCRIBE_PUSHER_CHANNELS,
  payload: key,
});
