const sectionsHeaders = [
  ...document.querySelectorAll('.wi-table-section-header.expandable'),
];

sectionsHeaders.forEach(item => {
  item.addEventListener('click', () => {
    item.classList.toggle('wi-section-open');
    item.parentElement.nextElementSibling.classList.toggle('wi-hide-section');
  });
});
