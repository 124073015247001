import React from 'react';

function ReactDatesCloseButton(props) {
  return (
    <svg
      viewBox="0 0 12 12"
      style={{ width: 15, height: 9, verticalAlign: 'middle', fill: '#82888a' }}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M11.53.47a.75.75 0 00-1.061 0l-4.47 4.47L1.529.47A.75.75 0 10.468 1.531l4.47 4.47-4.47 4.47a.75.75 0 101.061 1.061l4.47-4.47 4.47 4.47a.75.75 0 101.061-1.061l-4.47-4.47 4.47-4.47a.75.75 0 000-1.061z"
      />
    </svg>
  );
}

export default ReactDatesCloseButton;
