import { Action } from '^/match-centre/common/types';
import { Tooltips, Weather } from '^/match-centre/summary-panel/types';

export const initialState: Tooltips | null = null;

export function tooltips(state = initialState, action: Action<any, any>) {
  switch (action.type) {
    default:
      return state;
  }
}

export const initialWeatherState: Weather | null = null;

export function weather(state = initialWeatherState, action: Action<any, any>) {
  switch (action.type) {
    default:
      return state;
  }
}
