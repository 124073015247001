import { Ball, ExtrasBBB, TeamReview } from '^/match-centre/bbb/types';

export const hasNoRuns = (runs: string) => Number(runs) === 0;
export const isMidRuns = (runs: string) => Number(runs) > 0 && Number(runs) < 4;
export const isHighRuns = (runs: string) => Number(runs) > 3;
export const isWicket = (isOut: string | null) => isOut && '1';
export const isTooltipActive = (a: string | null, b: string): boolean =>
  a === b;

export function getReviewTeamFromBall(ball: Ball): string | undefined {
  if (ball.reviewer_team === TeamReview.BATTING) {
    return ball.batting_team_id;
  } else if (ball.reviewer_team === TeamReview.BOWLING) {
    return ball.bowling_team_id;
  }
}

export function showExtras(ball: Ball): any[] {
  const zero = 0;

  const hasNoBalls = parseFloat(ball.noballs) > zero ? ExtrasBBB.NO_BALLS : '';
  const hasWides = parseFloat(ball.wides) > zero ? ExtrasBBB.WIDES : '';
  const hasByes = parseFloat(ball.byes) > zero ? ExtrasBBB.BYES : '';
  const hasLegByes = parseFloat(ball.legbyes) > zero ? ExtrasBBB.LEGBYES : '';

  return [hasNoBalls, hasWides, hasByes, hasLegByes];
}

export function showBallReviews(ball: Ball): string {
  let reviewString = '';

  if (ball.reviewer_team) {
    reviewString += TeamReview.REVIEW;

    if (ball.review_success === '1') {
      const tick = '\u2713';
      reviewString += ` ${tick}`;
    }
  }
  return reviewString;
}
