import React from 'react';
import { connect } from 'react-redux';

import { Ball } from '^/match-centre/bbb/types';
import { getReviewTeamFromBall, isWicket } from '^/match-centre/bbb/utils';
import { Player } from '^/match-centre/scorecard/types';
import { playerSelector } from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import { getTeamNameFromTeamId } from '^/match-centre/summary-panel/selectors';

export interface StateProps {
  bowlingPlayer: Player | undefined;
  battingPlayer: Player | undefined;
  getTeamNameFromReviewId: (id: string) => void;
}

export interface OwnProps {
  ball: Ball;
}

export type Props = OwnProps & StateProps;

export const BallTooltipContent = ({
  ball,
  bowlingPlayer,
  battingPlayer,
  getTeamNameFromReviewId,
}: Props) => {
  const reviewTeamId = getReviewTeamFromBall(ball);
  return (
    <>
      <p className="wi-ball-tooltip-content-top">
        <span className="bold">
          Over {ball && ball.over_number}, {ball && ball.ball_number}:
        </span>{' '}
        {bowlingPlayer && bowlingPlayer.known_as} to{' '}
        {battingPlayer && battingPlayer.known_as}, {ball && ball.batsman_shot}
      </p>

      {isWicket(ball.is_out) && (
        <p className="wi-ball-tooltip-content-center">
          <span className="bold">{ball.dismissal_str}</span>
        </p>
      )}

      <div className="wi-ball-tooltip-content-left">
        {reviewTeamId && (
          <p>
            <span className="bold">Review by:</span>{' '}
            {getTeamNameFromReviewId(reviewTeamId)}{' '}
            {ball.review_success !== '0' && '(Successful)'}
          </p>
        )}
        {Boolean(ball.ball_line || ball.ball_length) && (
          <p>
            <span className="bold">
              {Boolean(ball.ball_line) && 'Line'}
              {Boolean(ball.ball_line) && Boolean(ball.ball_length) && ', '}
              {Boolean(ball.ball_length) && 'length'}:
            </span>{' '}
            {ball.ball_line}
            {Boolean(ball.ball_line) && Boolean(ball.ball_length) && ', '}
            {ball.ball_length}
          </p>
        )}

        {Boolean(ball.ball_speed) && (
          <p>
            <span className="bold">Speed:</span> {ball.ball_speed}
          </p>
        )}
      </div>

      <div className="wi-ball-tooltip-content-right">
        {ball && ball.ball_detail && (
          <p>
            <span className="bold">Ball Type:</span> {ball.ball_detail}
          </p>
        )}

        {bowlingPlayer && bowlingPlayer.bowling_stock && (
          <p>
            <span className="bold">
              {bowlingPlayer.known_as}'s stock delivery:
            </span>{' '}
            {bowlingPlayer.bowling_stock}
          </p>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => ({
  bowlingPlayer: playerSelector(state, props.ball.bowling_player_id),
  battingPlayer: playerSelector(state, props.ball.batting_player_id),
  getTeamNameFromReviewId: (id: string) => getTeamNameFromTeamId(state, id),
});

export default connect(mapStateToProps)(React.memo(BallTooltipContent));
