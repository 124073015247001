import React from 'react';
import { connect } from 'react-redux';

import {
  extraRunsSelector,
  totalWicketsAndOversSelector,
} from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';

export interface StateProps {
  totalWicketsAndOvers: string;
  extraRuns: string | null;
}

export type Props = StateProps;

export const BatterTotalInfo = ({ totalWicketsAndOvers, extraRuns }: Props) => (
  <>
    <div className="top">Total</div>
    <div className="bottom">
      {totalWicketsAndOvers}
      {extraRuns && ` (${extraRuns} extras)`}
    </div>
  </>
);

const mapStateToProps = (state: StoreState): StateProps => ({
  totalWicketsAndOvers: totalWicketsAndOversSelector(state),
  extraRuns: extraRunsSelector(state),
});

export default connect(mapStateToProps)(React.memo(BatterTotalInfo));
