import { Column, Row } from '@dabapps/roe';
import 'moment-timezone';
import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';

import {
  DEFAULT_BLUE,
  DEFAULT_YELLOW,
  hexOrDefault,
} from '^/match-centre/common/utils';
import { hasPitchvizSelector } from '^/match-centre/graphs/selectors';
import { Innings, Match } from '^/match-centre/scorecard/types';
import {
  getColoursForMatch,
  hasMatchStarted,
  hasWinvizDataSelector,
  isMatchComplete,
  isMatchLive,
  matchSelector,
} from '^/match-centre/selectors';
import StatsDisplay from '^/match-centre/stats-display';
import { StoreState } from '^/match-centre/store/types';
import Score from '^/match-centre/summary-panel/score';
import {
  getCurrentTeamOneClosureSelector,
  getCurrentTeamTwoClosureSelector,
  getTeamNameOne,
  getTeamNameTwo,
  getTeamOneInningsSelector,
  getTeamTwoInningsSelector,
  getWeather,
} from '^/match-centre/summary-panel/selectors';
import { Weather } from '^/match-centre/summary-panel/types';

export interface StateProps {
  match: Match | null;
  displayTeamNameOne: string | null;
  displayTeamNameTwo: string | null;
  teamOneInnings: ReadonlyArray<Innings> | [];
  teamTwoInnings: ReadonlyArray<Innings> | [];
  displayTeamOneClosure: string | null;
  displayTeamTwoClosure: string | null;
  isLive: boolean;
  matchColours: ReadonlyArray<string>;
  hasPitchviz: boolean;
  hasWinviz: boolean;
  displayLocalTime: boolean;
  weather: Weather | null;
}

export type Props = StateProps;

export const SummaryPanel = ({
  match,
  displayTeamNameOne,
  displayTeamNameTwo,
  teamOneInnings,
  teamTwoInnings,
  displayTeamOneClosure,
  displayTeamTwoClosure,
  isLive,
  matchColours: [team1Colour, team2Colour],
  hasPitchviz,
  hasWinviz,
  displayLocalTime,
  weather,
}: Props) => {
  if (!match) {
    return null;
  }
  return (
    <div className="wi-summary-panel">
      <div className="wi-summary-panel-teams-wrapper">
        <div className="wi-summary-panel-team">
          <div
            className="wi-summary-panel-block"
            style={{
              backgroundColor: hexOrDefault(team1Colour, DEFAULT_YELLOW),
            }}
          />
          <div className="wi-summary-panel-team-image">
            {match.team1_logo_url && (
              <img
                src={match.team1_logo_url}
                alt={`${match.team1_name} badge`}
              />
            )}
          </div>

          <div className="wi-summary-panel-team-name">{displayTeamNameOne}</div>

          <div className="wi-summary-panel-team-result">
            <p>
              <Score innings={teamOneInnings} />
            </p>
            <small>{displayTeamOneClosure}</small>
          </div>
        </div>

        <div className="wi-summary-panel-team">
          <div
            className="wi-summary-panel-block"
            style={{ backgroundColor: hexOrDefault(team2Colour, DEFAULT_BLUE) }}
          />
          <div className="wi-summary-panel-team-image">
            {match.team2_logo_url && (
              <img
                src={match.team2_logo_url}
                alt={`${match.team2_name} badge`}
              />
            )}
          </div>

          <div className="wi-summary-panel-team-name">{displayTeamNameTwo}</div>

          <div className="wi-summary-panel-team-result">
            <p>
              <Score innings={teamTwoInnings} />
            </p>
            <small>{displayTeamTwoClosure}</small>
          </div>
        </div>
      </div>

      {displayLocalTime && (
        <div className="wi-match-time-at-venue">
          <div className="wi-match-time-at-venue-inner">
            <p className="time-at-venue-title">Time at venue</p>
            <p className="time-at-venue">
              <Moment
                tz={match.ground_time_zone}
                interval={1000}
                format="HH:mm"
              />
            </p>
          </div>
        </div>
      )}

      {weather && (
        <div className="match-results-weather-outer">
          <Row className="match-results-weather">
            <Column xs={12} lg={14} xl={15} className="padding-left-none">
              <a href={weather.link} target="_blank">
                <div className="weather-link-logo">
                  <img
                    src="/static/images/accuweather_logo_white.png"
                    alt="Accuweather logo"
                  />
                </div>

                <div className="weather-link-location">
                  <svg
                    className="float-left map-marker-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="17"
                    viewBox="0 0 12 17"
                  >
                    <g fillRule="evenodd" transform="translate(0.5)">
                      <path
                        fillRule="nonzero"
                        d="M5.625,0 C2.523375,0 0,2.523375 0,5.625 C0,6.6729375 0.29028125,7.695625 0.83965625,8.582875 L5.3048125,15.7784062 C5.39034375,15.91625 5.54103125,16 5.70309375,16 C5.70434375,16 5.7055625,16 5.7068125,16 C5.87028125,15.9987188 6.02128125,15.912375 6.10525,15.772125 L10.4565937,8.506875 C10.9756563,7.638375 11.25,6.64184375 11.25,5.625 C11.25,2.523375 8.726625,0 5.625,0 Z M9.6520625,8.0255625 L5.696,14.6308125 L1.63646875,8.0889375 C1.1791875,7.3504375 0.93125,6.4984375 0.93125,5.625 C0.93125,3.0403125 3.0403125,0.93125 5.625,0.93125 C8.2096875,0.93125 10.315625,3.0403125 10.315625,5.625 C10.315625,6.47253125 10.0840312,7.30275 9.6520625,8.0255625 Z"
                      />
                      <path
                        fillRule="nonzero"
                        d="M5.625,2.8125 C4.0741875,2.8125 2.8125,4.0741875 2.8125,5.625 C2.8125,7.16590625 4.05365625,8.4375 5.625,8.4375 C7.21571875,8.4375 8.4375,7.14896875 8.4375,5.625 C8.4375,4.0741875 7.1758125,2.8125 5.625,2.8125 Z M5.625,7.50625 C4.58571875,7.50625 3.74375,6.66146875 3.74375,5.625 C3.74375,4.591125 4.591125,3.74375 5.625,3.74375 C6.658875,3.74375 7.503125,4.591125 7.503125,5.625 C7.503125,6.64634375 6.68075,7.50625 5.625,7.50625 Z"
                      />
                    </g>
                  </svg>

                  <div className="float-left weather-link-title">
                    {match.weather.city}
                  </div>
                </div>
              </a>
            </Column>

            <Column xs={6} className="weather-temperature">
              {weather.temperature}
            </Column>

            <Column
              xs={6}
              lg={4}
              xl={3}
              className="weather-icon padding-right-none"
            >
              <img
                className="float-right"
                src={weather.icon_white}
                alt="Weather icon"
              />
            </Column>
          </Row>
        </div>
      )}

      {match.result_string && (
        <div className="wi-match-results">
          <p>{match.result_string}</p>
        </div>
      )}

      {match.match_situation_str && (
        <div className="wi-match-results">
          <p>{match.match_situation_str}</p>
        </div>
      )}

      {(hasPitchviz || hasWinviz) && isLive && <StatsDisplay />}
    </div>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  match: matchSelector(state),
  displayTeamNameOne: getTeamNameOne(state),
  displayTeamNameTwo: getTeamNameTwo(state),
  teamOneInnings: getTeamOneInningsSelector(state),
  teamTwoInnings: getTeamTwoInningsSelector(state),
  displayTeamOneClosure: getCurrentTeamOneClosureSelector(state),
  displayTeamTwoClosure: getCurrentTeamTwoClosureSelector(state),
  isLive: isMatchLive(state),
  matchColours: getColoursForMatch(state),
  hasPitchviz: hasPitchvizSelector(state),
  hasWinviz: hasWinvizDataSelector(state),
  displayLocalTime: !hasMatchStarted(state) && !isMatchComplete(state),
  weather: getWeather(state),
});

export default connect(mapStateToProps)(React.memo(SummaryPanel));
