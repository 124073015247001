import classnames from 'classnames';
import React from 'react';

const COLOR_CODE: ReadonlyArray<Record<string, string>> = [
  {
    name: 'Dot Ball',
    class: 'wi-ball-dot',
  },
  {
    name: '1-3 runs',
    class: 'mid-runs',
  },
  {
    name: '4 or 6 runs',
    class: 'high-runs',
  },
  {
    name: 'Wicket',
    class: 'wicket',
  },
];

export const ColourCode = () => (
  <div className="wi-colour-code-list">
    {COLOR_CODE.map((code: Record<string, string>, index: number) => (
      <div key={index} className="wi-colour-code">
        <div className={classnames('wi-colour-code-class', code.class)} />
        <div className="wi-colour-code-name">{code.name}</div>
      </div>
    ))}
  </div>
);

export default React.memo(ColourCode);
