import {
  RESET_INNINGS_TAB,
  RESET_SELECTED_BALL,
  UPDATE_BBB,
  UPDATE_INNINGS_TAB,
  UPDATE_SELECTED_BALL,
} from '^/match-centre/bbb/actions';
import { Ball, BBB } from '^/match-centre/bbb/types';
import { Action } from '^/match-centre/common/types';

export const initialState: BBB | null = null;

const filterExistingBallsList = (
  existing: ReadonlyArray<Ball>,
  id: string
): ReadonlyArray<Ball> => {
  return existing.filter((ball: Ball) => ball.ball_id !== id);
};

const generateBbbList = (
  existing: ReadonlyArray<Ball>,
  incoming: Ball
): ReadonlyArray<Ball> => {
  return filterExistingBallsList(existing, incoming.ball_id).concat(incoming);
};

export function bbb(state = initialState, action: Action<any, any>) {
  switch (action.type) {
    case UPDATE_BBB:
      return (
        state && {
          ...state,
          ball: generateBbbList(state.ball, action.payload),
        }
      );

    default:
      return state;
  }
}

export const initialAciveInningState: string | null = null;

export function activeInningsTab(
  state = initialAciveInningState,
  action: Action<any, any>
) {
  switch (action.type) {
    case UPDATE_INNINGS_TAB:
      return action.payload;

    case RESET_INNINGS_TAB:
      return null;

    default:
      return state;
  }
}

export const initialSelectedBall: string | null = null;

export function selectedBall(
  state = initialSelectedBall,
  action: Action<any, any>
) {
  switch (action.type) {
    case UPDATE_SELECTED_BALL:
      return action.payload;

    case RESET_SELECTED_BALL:
      return null;

    default:
      return state;
  }
}
