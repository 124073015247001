import React from 'react';
import PropTypes from 'prop-types';
import { Column, ContentBox, ContentBoxFooter } from '@dabapps/roe';
import WiPlayerPlaceholder from './wi-player-placeholder';

class WiPlayerCard extends React.Component {
  render() {
    const { name, link, image, flag } = this.props;
    return (
      <Column className="wi-player-column" xs={8} sm={6} md={6} lg={4} xl={3}>
        <ContentBox className="wi-player-card">
          <a
            href={link}
            className="content-box-header wi-player-card-header"
            style={
              image && {
                backgroundImage: `url(${image}), radial-gradient(#7E8086, #292929)`,
              }
            }
          >
            {flag && <img className="wi-player-card-header-flag" src={flag} />}
            {!image && <WiPlayerPlaceholder className="player-placeholder" />}
          </a>
          <ContentBoxFooter className="wi-player-card-footer">
            <p className="wi-player-card-title">
              <a href={link}>{name}</a>
            </p>
          </ContentBoxFooter>
        </ContentBox>
      </Column>
    );
  }
}

WiPlayerCard.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  flag: PropTypes.string,
};

export default WiPlayerCard;
