import React from 'react';
import { connect } from 'react-redux';

import { Batting } from '^/match-centre/scorecard/types';
import {
  playerNameAndBattingHand,
  playerPageSelector,
} from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';

export interface OwnProps {
  batter: Batting;
}

export interface StateProps {
  getPlayerNameAndBattingHandFromId: (id: string) => string;
  playerPage: string | undefined;
}

export type Props = OwnProps & StateProps;

export const BatterDetails = ({
  batter,
  getPlayerNameAndBattingHandFromId,
  playerPage,
}: Props) => {
  return (
    <>
      <div className="top">
        {playerPage ? (
          <div className="player-cell-name">
            <a
              href={playerPage}
              target="_blank"
              className="wi-player-cell-link"
            >
              {getPlayerNameAndBattingHandFromId(batter.player_id)}
            </a>
          </div>
        ) : (
          <div className="wi-player-cell-name">
            {getPlayerNameAndBattingHandFromId(batter.player_id)}
          </div>
        )}
      </div>
      <div className="bottom">{batter.dismissal_str}</div>
    </>
  );
};

const mapStateToProps = (state: StoreState, props: OwnProps): StateProps => {
  return {
    getPlayerNameAndBattingHandFromId: (id: string) =>
      playerNameAndBattingHand(state, id),
    playerPage: playerPageSelector(state, props.batter.player_id),
  };
};

export default connect(mapStateToProps)(React.memo(BatterDetails));
