import { createSelector } from 'reselect';

import { ConstructedFow, Fow } from '^/match-centre/scorecard/types';
import {
  allPlayersSelector,
  selectedInningsSelector,
} from '^/match-centre/selectors';

export const selectedFowSelector = createSelector(
  selectedInningsSelector,
  (selectedInnings): ReadonlyArray<Fow> => {
    return (selectedInnings && selectedInnings.fow) || [];
  }
);

export const reducedFowSelector = createSelector(
  selectedFowSelector,
  (fow): ReadonlyArray<Fow> => {
    return (
      fow &&
      [...fow].reduce(
        (acc: Fow[], curr: Fow) => [
          ...acc,
          ...(curr.fow_type === 'out' || curr.fow_order === 'complete'
            ? [curr]
            : []),
        ],
        []
      )
    );
  }
);

export const constructedFowSelector = createSelector(
  reducedFowSelector,
  allPlayersSelector,
  (fow, allPlayers): ReadonlyArray<ConstructedFow> => {
    const playerName = (id: string) => {
      const player = allPlayers.find(obj => obj.player_id === id);
      return player ? player.known_as : 'N/A';
    };

    return (
      fow &&
      fow.map((item: Fow) => ({
        ...item,
        dismissed:
          item.out_player_id !== '0' ? playerName(item.out_player_id) : '',
        partner:
          item.player1_id !== item.out_player_id
            ? playerName(item.player1_id)
            : playerName(item.player2_id),
      }))
    );
  }
);

export const hasFowDataSelector = createSelector(
  constructedFowSelector,
  (fow): boolean => Boolean(fow.length)
);
