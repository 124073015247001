import { createSelector } from 'reselect';

import {
  infoTooltipsSelector,
  tooltipsSelector,
} from '^/match-centre/selectors';
import { StoreState } from '^/match-centre/store/types';
import { ProductMeta } from '^/match-centre/summary-panel/types';

export const tooltipsMetaSelector = createSelector(
  tooltipsSelector,
  (tooltips): ReadonlyArray<ProductMeta> | null =>
    tooltips && tooltips.product_meta
);

export const metaDataSelector = (key: string) =>
  createSelector(
    tooltipsMetaSelector,
    (meta): ProductMeta | null =>
      (meta && meta.find(item => item.product === key)) || null
  );

export const windiesBbbMetaSelector = metaDataSelector('windiesbbb');

export const winvizMetaSelector = metaDataSelector('winviz');

export const expectedRunsMetaSelector = metaDataSelector('xr');

export const expectedWicketsMetaSelector = metaDataSelector('xw');

export const boundaryPercentMetaSelector = metaDataSelector('boundary_percent');

export const dotBallPercentMetaSelector = metaDataSelector('dot_ball_percent');

export const isInfoTooltipActive = createSelector(
  infoTooltipsSelector,
  (state: StoreState, id: string) => id,
  (tolltips, id): boolean => tolltips.has(id)
);
