const wiNavbarSearchLabel = document.getElementById('wi-navbar-search-label');
const wrapper = document.getElementById('wrapper');
const wiNavbarSearchFormWrapper = document.getElementById(
  'wi-navbar-search-form-wrapper'
);
const wiNavbar = document.getElementById('wi-navbar');
const wiButtonClear = [
  ...document.querySelectorAll('.wi-navbar .wi-button-clear'),
];

function toggleClassNames() {
  wrapper.classList.toggle('has-search-active');
  wiNavbar.classList.toggle('has-search-active');
  wiNavbarSearchFormWrapper.classList.toggle('display-none');
}

if (wiNavbarSearchLabel) {
  wiNavbarSearchLabel.addEventListener('click', toggleClassNames);
}

if (wiButtonClear.length) {
  wiButtonClear.forEach(function(item) {
    item.addEventListener('click', toggleClassNames);
  });
}
